
@import "./variable";

.NavColor{
    color: $blue-color;
}


.btn-primary:hover{
    background: #042244!important;
    outline:none;
}


.btn-primary:focus{
    outline:none!important;
    box-shadow: none;
}
textarea{resize: none}


