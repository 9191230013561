.imageBox {
  border-left: 1px solid rgb(25, 117, 110);
  border-right: 1px solid rgb(25, 117, 110);
  border-top: 2px solid rgb(1, 167, 153);
  border-bottom: 2px solid rgb(1, 167, 153);
  align-self: center !important;
  min-width: 150px;
  max-width: 150px;
  width: 150px !important;
  height: 120px !important;
  margin: 20px 0 5px 17px;
  cursor: pointer;
}
.buttonUpload-parrent{

.buttonUpload-manager{
  font-size:13px!important;
  width:100%;
  margin:0 auto;
  border-radius: 50px;
  margin-top:10px;
  color:#fac227 ;
  background: #fff;
  border-color:#fac227 ;
  // font-family: $fontBold;
  transition: 0.5s;
  &:hover{
      background: #fac227 !important;
      color:#fff!important;
  }
}

}

.listCountryName{
  text-align: left;
  flex-direction: row !important;
  display: flex !important;
}
.autoSearch-manager{
  height: 50px;

  .MuiAutocomplete-inputRoot .MuiAutocomplete-input{
    color: #ffffff !important;
    padding: 2.5px 4px!important;
  }

  .MuiAutocomplete-clearIndicator {
      margin-top: -0 !important;
      margin-right: 5px;
  }

  .MuiInputBase-root{
    height:50px;
  }

  .MuiAutocomplete-endAdornment {
    top: 6px;
  }

  .MuiAutocomplete-popupIndicatorOpen{
      border-right: 1px solid #acc1cb!important;
      border-left:none!important;
  }

  .MuiAutocomplete-popupIndicator{
      position: relative;
      top: -1px;
      border-left: 1px solid #acc1cb;
      border-right:none;
      border-radius: 0;

      svg{
          font-size: 35px!important;

      }
  }

}
  .connectToolKid{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.myProfileImage-manager {
  max-width: 80px !important;
  height: 80px !important;
  margin: 0 auto;
  width: 100% !important;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
}
.iconTooltipManager {
  /* // border: solid black !important; */
  z-index: 0 !important;
  margin-top: 23px !important;
  margin-left: 4px;
}
.imgPreview-manager {
  align-self: center !important;
  min-width: 150px;
  max-width: 150px;
  width: 150px !important;
  margin: 20px 0 5px 17px;
  padding-left: 20px !important;
  cursor: pointer;
  flex-direction: column;
}

.tags {
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;

}
@media screen and (min-width: 375px) and (max-width: 812px) {
  .tags_button {

    font-size: 12px !important;
    padding: 8px 15px !important;
    margin-left: 20px !important;
  }

}
.MuiInputBase-input  {
  color: #ffffff!important;

  opacity:1!important;
}
.MuiInputBase-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #ffffff!important;
  opacity:1!important;
}
.MuiInputBase-input::-moz-placeholder { /* Firefox 19+ */
  color: #ffffff!important;
  opacity:1!important;
}
.MuiInputBase-input:-ms-input-placeholder { /* IE 10+ */
  color: #ffffff!important;
  opacity:1!important;
}
.MuiInputBase-input:-moz-placeholder { /* Firefox 18- */
  color: #fcfcfc!important;
  opacity:1!important;
}
.drop-down-stl-manager {
  // border: solid 0 !important;
  // max-width: 300px;
  // min-width: 100px;
  width: 100% !important;
  color: white !important;

  background-color: #694b99 !important;
}
.categoryAC-manager{
  color: #ffff !important;
}
.textarea-style{
color: white !important;
background-color: #694b99 !important;

}
#inputIdText::placeholder{
  color: #ffff !important;
}
.tags_button {
  margin-left: 30px;
  margin-top: 15px;
  background-color: #fac227 !important;
  color: #492ec4 !important;
  font-family: "CircularAirBold";
  font-size: 17px ;
  cursor: pointer;
  transition: 0.3s;
  border: none !important;
  text-transform: capitalize !important;
  border-radius: 50px !important;
  padding: 8px 35px ;
  &:hover{
    background-color: #ffd152 !important;
  color: #5339c7 !important;
  }
}


.tag-class {
  text-align: center;
  display: block;
  margin-bottom: 20px;
  margin-top: 30px !important;
}

.tagText {
  width: auto !important;
  border-radius: 15px !important;
  background: #694b99 !important;
  margin-right: 10px;
  border: none !important;
  color: #fff !important;

}

.tagContainer {
  padding-top: 10px !important;
  height: auto !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1rem;
  align-self: center;
  align-items: center;
}

.tag-x {
  display: flex;
  //padding: 0 10px 0 10px;
  padding: 10px;
  margin: 10px;
  align-items: center;
  height: 30px !important;
  border: solid 1px #694b99;
  border-radius: 15px;
}

.tag-input {
  width: 40px;
  height: 30px;
  margin-top: 5px;
  color: #694b99;
  cursor: pointer;

}
.tag-input:hover{
  color: #000000 !important;
  font-weight: bold !important;
}

.enabledBtn {
  background-color: #fac227 !important;
}

.disabledBtn {
  background-color: rgb(167, 167, 167) !important;
}

.addBtn {
  color: white;
  border-radius: 50px !important;
  font-size: 15px;
  padding: 5px 25px 5px 25px !important;
  text-align: center;
  border: none !important;
}

.main-tag {
  width: 100% !important;
  text-align: left !important;
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #694b99 ;
  font-weight: bold;
}
#inputID::placeholder {
  color: #ffffff !important;
  opacity: 10 !important;
}

.logo_image1 > .my_logo1 {
  height: 65px;
  width: 65px;
  position: absolute;
  margin: 0 !important;
  top: 0;
  z-index: 5;
  margin-left: -2rem !important;

}

.header-M {
  padding: 0 !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 65px !important;

  .head-M2 {
    background-color: #0d878c;
    width: 50%;

  }

  .head-M1 {
    background-color: #19b3bb;
    width: 50%;
  }
}

.File-button-stl {
  min-width: 150px;
  max-width: 150px;
  width: 150px !important;
  height: 120px !important;
  color: rgb(0, 117, 108);
  background-color: transparent !important;
  border: 0 !important;
}

.Update-btn-manager {
  background-color: #20b55c !important;
  color: white;
  font-family: "CircularAirBold";
  font-size: 14px !important;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50px !important;
  border: none !important;
  padding: 7px 30px 7px 30px !important;

  &:hover {
    background-color: #10522b !important;
  }
}





.searchEventMobile .location-search-input-manager{
  padding-top: 5px!important;
  margin-top: 0px!important;
}
.search-bar-div .location-search-input-manager{
  color: rgb(0, 0, 0) !important;
  width:100%;
  border:none;
  background-color: #ffffff !important;
  border-radius: 15px;
    margin-top: 10px;
    padding-right:10px;
}
.search-bar-div .location-search-input-manager{
  color: #492ec4 !important;
  z-index: 100 !important;
}



#zing-map::placeholder{
  color: #492ec4 !important;
}


.input-manager {
  margin-bottom: 10px;
  font-size: 15px !important;
  height: 40px;
  background: #694b99 !important;
  height: 50px !important;
  border-radius: 15px !important;
  color: #fff !important;
}

.Label-text-manager {
  margin-top: 10px;
  // color:#040608!important;

}

.Ok-manager {
  cursor: pointer;
  font-weight: bold;
  border: solid 2px black;
  // padding: 10px;
  height: 100px !important;
  width: 100%;

}

.video-size {
  max-width: 350px !important;
  max-height: 200px !important;
  border-radius: 10px;
}

.myManagerPopup {
  overflow-x: scroll !important;


  textarea {
    border-radius: 15px !important;
  }


  .MuiInputBase-input {
    border-radius: 0 !important;
    height: auto;
  }

  .MuiAutocomplete-root {
    border-radius: 15px !important;
  }

.upload-manager{
  background-color: #fac227 !important;
  border: none !important;
  border-radius: 50px !important;
&:hover{
  background-color: #eeae00 !important;
  
}
}
  .myMngBtn {
    background-color: #fac227 !important;
  color: #492ec4 !important;
    font-size: 14px !important;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 50px !important;
    border: none !important;
    padding: 7px 30px 7px 30px !important;

    &:hover {
      background: #e6a903 !important;
    }
  }
}

.search-bar-div-manager {

  justify-content: space-around !important;

  // width: 95% !important;
  margin-bottom: 10px;
  font-size: 15px !important;

  background: #694b99 !important;
  height: 50px !important;
  border-radius: 15px !important;
  color: #fff !important;

  input {
    color: #fff;
    padding-left: 10px;
  }

  input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #fff !important;
  }

  input::-moz-placeholder { /* Firefox 19+ */
    color: #fff !important;
  }

  input:-ms-input-placeholder { /* IE 10+ */
    color: #fff !important;
  }

  input:-moz-placeholder { /* Firefox 18- */
    color: #fff !important;
  }
}

.search-bar-div-manager .location-search-input {
  width: 100%;
  border: none;
  background-color: #694b99 !important;
  border-radius: 15px;
  margin-top: 10px;
  padding-right: 10px;
  color: white !important;
}

.nrmgreenBtnEvt {
  background-color: #fac227 !important;
  color: #492ec4 !important;
  border-radius: 50px;
  font-family: "CircularAirBold";
  font-size: 17px !important;
  cursor: pointer;
  transition: 0.3s;
  border: none !important;
  text-transform: capitalize !important;
  border-radius: 50px !important;
  padding: 8px 35px !important;

  &:hover {
    background-color: #ecad00 !important;
  }
}

.nrmredBt {
  background-color: #b52020 !important;
  color: white;
  font-family: "CircularAirBold";
  font-size: 17px !important;
  cursor: pointer;
  transition: 0.3s;
  border: none !important;
  text-transform: capitalize !important;
  border-radius: 50px !important;
  padding: 8px 35px !important;
  white-space: nowrap;
  &:hover {
    background-color: #000000 !important;
  }
}
@media screen and (max-device-width : 767px){
  .buttonUpload-manager{
    font-size:13px!important;
    width:100%;
    margin:0 auto;
    border-radius: 50px!important;
    margin-top:10px;
    color:#fac227 !important;
    background: #fff!important;
    border-color:#fac227 !important;
    // font-family: $fontBold;
    transition: 0.5s;
    &:hover{
        background: #fac227 !important;
        color:#fff!important;
    }
  }
}
@media screen and (min-width: 375px) and (max-width: 812px) {
  .nrmgreenBtnEvt {
    font-size: 12px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    font-weight: 700 !important;
    font-family: Arial, Helvetica, sans-serif !important;
    border-radius: 10px !important;

  }
  .nrmredBt {
    border-radius: 10px !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    font-weight: 700 !important;
    font-family: Arial, Helvetica, sans-serif !important;
  }
}

.promotion_buttons {
  padding: 4px;
  width: 100%;
  justify-content: space-between !important;
  display: flex;
}

.promotion_space {
  font-size: 25px;
  margin: 15px;
}
.stripeButton{
  align-self: center;
  height: 35px;
  cursor: pointer;
  margin-left: 35px;
  margin-top: 20px;
}

