/*components/bothusers/friends/friends.scss*/
$blueevents: #446cdb !important;
$yellowEvents: #f4d35e !important;
$greenEvents: #00b4b1 !important;
$iconsColor: #0ee6e0 !important;
$purpleColor: #4b0082 !important;
$followColor: magenta !important;
$seeProfileColor: green !important;
$reportColor: red !important;
$details: #3ab54a !important;
$btnCol: #20b55c !important;

$radius: 20px;

@import "../../../Assets/css/variable";
.border {
  border-width: 1px;
  border-style: solid;
  border-color: red;
}
.userLogo{
  width: 60px;
  height: 60px;
  border-radius: 40px;
}
.dFlexUser{
  display: flex ;
}
.follow {
  background-color: $followColor;
  color: white;
  font-size: 9px;
}

.report {
  background-color: $reportColor;
  color: white;
  font-size: 9px;
}

.categorii {
  color: $followColor;
}
.follow-btn{
  cursor: pointer;
  
}
.event{
  transition: 2s;
}
.searchSpace{
  margin: 10px 0px;
}
.seeAllBtn{
  background-color: #20b55c !important;
    color: white;
    padding: 7px 30px 7px 30px;
    margin: 15px 0px;
    max-width: 150px;
    border-radius: 50px;
    font-family: "CircularAirBold";
    font-size: 14px;
    -webkit-box-shadow: 3px 3px 36px 5px rgba(0,0,0,0.13);
    -moz-box-shadow: 3px 3px 36px 5px rgba(0,0,0,0.13);
    box-shadow: 3px 3px 36px 5px rgba(0,0,0,0.13);
    cursor: pointer;
    transition: 0.3s;

    &:hover{
      background-color: #1b9a4e !important;
    }
}
.socialNetworksUP{
  text-align: right;
  justify-content:flex-end;
}
.oHidden{
  overflow: hidden;
}


.usrProfileModal{
  .btnProfile{
    border-radius: 50px!important;
    border:none!important;
    width:100%;
    font-size:15px!important;
    transition:0.3s;

    &:hover{
      opacity:0.8;
    }
  }

  .Block{
    background: #6a4b9a !important;
  }

  .Unfollow{
    background: #e0868e !important;
  }

  .Follow{
    background: #4e178c !important;
  }

  .Report{
    background: #1fb6fe !important;
  }


  .profileEventsInfo{
    margin-top:30px;
    margin-bottom:20px;
    text-align: center;

    .noOfEvt {
      text-align: center;
      border-radius: 10px;
      color: #157f71;
      border: 1px solid #eee;
      font-family: $fontBold;
      background-color: white !important;
    }
  }
}





.imageUserBoxModal {
  width:80px!important;
  max-width: 80px!important;
  height:80px!important;
  border:1px solid #eee;
  border-radius: 50%;
  object-fit: cover;
}

// .follow-report-user{
// }
.parent-input-btns{
  flex-wrap: unset !important;
  width: 100% !important;

  .send-messages-btn{
    // border: solid blue !important;
    // flex-wrap: unset !important;
    margin-left: 0 !important;
    background-color: transparent !important;
    border-radius: 0 !important;
    color: #083d77;
    font-weight: bold !important;
    font-size: 18px !important;
    border-left: 0 !important;
    border-radius:  0 0 20px 0  !important;
    border: 1px solid black  ;
    // border-right: 0 !important;
  }
  .send-messages-btn:hover{
    background-color: rgb(0, 141, 206) !important;
    
  }
  .mesage-userprof{
    border: 1px solid black  !important;
    border-right: 0 !important;
    border-radius: 20px 0 0 0 !important;
    // background-color: red !important;
  }

}
.messageInputUserProfile {
  border-radius: 12px!important;
  font-family: $fontBold;
  color:#492ec4!important;
  font-size:15px!important;
  width: 100% !important;
}

.MessageBtnUserProfile{
  background:#fac227 !important;
  border:none!important;
  border-radius: 50%!important;
  font-size: 20px!important;
  margin-left: -17px;
}
.eventOpenClosedUserProfile{
  font-size:17px;
}
.eventContainerBottomUserProfile {
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
  background-color: $blueevents;
  height:80px;
  width: 100% !important;

}
.no-margin-row{
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin: 0 !important;
  border: solid transparent !important;

  padding: 0 !important;
}
.no-margin-row-parent{
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  padding-top: 43px !important;
}
.trans-ition-user-profile {
  transition: 2s;
}
.profileDataLabel {
  margin-right: 5px;
  font-family: $fontBold;
  color: #093d77;
  display: flex;
 
  .svg-inline--fa{
    margin-top: 0.2em!important;
  }


  .profileData {
    label{
      margin-bottom:0!important;
      width: 70%;
      word-wrap: break-word;
    }
  }

}
.limit-width{
  width: 75% !important;
  min-width: 75% !important;
  max-width: 75% !important;
}
.joinButton-user-profile{
    background-color: #1fb6fe!important;
    width: 100% !important;
    font-size: 18px!important;
    border:none!important;
    border-radius: 50px!important;
    justify-content: center;
    // animation: pulse 2s infinite;
  
  
    &:hover{
      background: #188bc1!important;
    }
}

.makeEventBtn-user-profile {
  background-color: $btnCol;
  color: white;
  border-radius: 50px !important;
  font-family: $fontBold;
  font-size:20px;
  -webkit-box-shadow: 3px 3px 36px 5px rgba(0,0,0,0.13);
  -moz-box-shadow: 3px 3px 36px 5px rgba(0,0,0,0.13);
  box-shadow: 3px 3px 36px 5px rgba(0,0,0,0.13);
  cursor: pointer;
  transition: 0.3s;
  min-width: 150px;

    &:hover{
      background-color:#1b9a4e!important;
    }

  
}
.report-user-contain{
  width:100%;
  margin: 20px;
  transition: 0.3s;
  padding: 20px;
  border: solid 1px #eee;
  border-radius: 10px;
  text-align: center !important;
  .center-label-report{
    font-family: $fontBold;
    color: #093d77;
  font-size: 18px!important;
  }

    textarea{
      border-radius: 20px!important;
    }

    textarea::placeholder{
      color:#fff!important;
  }
}


@media screen and (max-device-width : 767px)
{
  .dFlexUser{
    display: inline-block !important;
  }

  .userLogo{
    width: 50px;
    height: 50px;
    border-radius: 40px;
  }
  .usrProfileModal {
    font-size:14px!important;

      .myProfileImage{
          height:40px!important;
      }

      .profileRightBtns{
        margin-top:20px;
      }


  }

}
.pop-over-block .popover {
  border: 1px solid #d7d7d7;
  text-align: center !important;

  .popmsg{
    padding:10px;
  }

}

.confirm-block-btn{
  background-color:#dc3545 !important;
  border-radius: 50px!important;
  border:none!important;
  width:70%;
  margin:15px;
  font-size:15px!important;
  transition:0.3s;
  // padding: 20px !important;

  &:hover{
    opacity:0.8;
  }
   
}
.justify-content-between{
  justify-content: space-between!important;
}
.justify-content-center {
  justify-content: center!important;
}
.imageBoxMyProfile {
  align-self: center !important;
  width: 150px !important;
  height: auto !important;
  background-color: white;
}
.descriptionBox{
  max-height: 100px;
  overflow-y: auto;
  border: 1px solid #eee;
  border-radius: 10px;
  padding:0px 10px;
  width: 100%!important;
}
.descriptionBox::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #76f5bc;
}

.descriptionBox::-webkit-scrollbar {
  border-radius: 5px;
  width: 5px;
  background-color: #fff;
}

.descriptionBox::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #093d77;
}
.categoriesBox{
    overflow-x: auto;
    width: 100%;
    display: inline-flex;
    margin-bottom: 10px;
}
.categoriesBox::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #76f5bc;
}

.categoriesBox::-webkit-scrollbar {
  border-radius: 5px;
  background-color: #fff;
  height: 5px;
}

.categoriesBox::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #093d77;
}
.disableHover{
  align-self: center !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin: 4px;
    font-size: 12px !important;
    width: auto !important;
    border-radius: 50px !important;
    color: #2d5683 !important;
    background-color: white !important;
    transition: 0.3s;
    cursor: default!important;
    white-space: nowrap;
}
.disableHover:hover{
    color: #2d5683 !important;
    background-color: white !important;
    cursor: default!important;
}