$blue-color:#00B5DA !important;
$btn-yellow: #fff !important;


$blue-color-hover: #009BE4 !important;
$blue-events: #083d77!important;



@font-face {font-family: "CircularAirLight"; 
    src: url("//db.onlinewebfonts.com/t/809eb889c78777517a6d252fd1f76eac.eot"); 
    src: url("//db.onlinewebfonts.com/t/809eb889c78777517a6d252fd1f76eac.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/809eb889c78777517a6d252fd1f76eac.woff2") format("woff2"),
     url("//db.onlinewebfonts.com/t/809eb889c78777517a6d252fd1f76eac.woff") format("woff"), 
     url("//db.onlinewebfonts.com/t/809eb889c78777517a6d252fd1f76eac.ttf") format("truetype"), 
     url("//db.onlinewebfonts.com/t/809eb889c78777517a6d252fd1f76eac.svg#Circular Air Light") format("svg"); }
  
  
     @font-face {font-family: "CircularAirBold"; src: url("//db.onlinewebfonts.com/t/6cf0ce88e2fb8e3c53cff722a765a50c.eot"); src: url("//db.onlinewebfonts.com/t/6cf0ce88e2fb8e3c53cff722a765a50c.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/6cf0ce88e2fb8e3c53cff722a765a50c.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/6cf0ce88e2fb8e3c53cff722a765a50c.woff") format("woff"), url("//db.onlinewebfonts.com/t/6cf0ce88e2fb8e3c53cff722a765a50c.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/6cf0ce88e2fb8e3c53cff722a765a50c.svg#Circular Air Bold") format("svg"); }
  
     @font-face {font-family: "CircularAirBook"; src: url("//db.onlinewebfonts.com/t/bd6213fa49bedc8cb050c63f14360efa.eot"); src: url("//db.onlinewebfonts.com/t/bd6213fa49bedc8cb050c63f14360efa.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/bd6213fa49bedc8cb050c63f14360efa.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/bd6213fa49bedc8cb050c63f14360efa.woff") format("woff"), url("//db.onlinewebfonts.com/t/bd6213fa49bedc8cb050c63f14360efa.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/bd6213fa49bedc8cb050c63f14360efa.svg#Circular Air Book") format("svg"); }


     $fontBold: 'CircularAirBold';
     $fontLight: 'CircularAirLight';
     $fontNormal: "CircularAirBook"; 