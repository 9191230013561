$blueevents: #2d5683 !important;
$yellowEvents: #f4d35e !important;
$greenEvents: #00b4b1 !important;
$iconsColor: #0ee6e0 !important;
$purpleColor: #4b0082 !important;
$followColor: magenta !important;
$seeProfileColor: green !important;
$reportColor: red !important;
$details: #492ec4 !important;

body,
html {
  height: 100%;
}
.shadowBox{
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12)
;
}
.findPeopleContainer {
  min-height: 700px !important;
}
.showEvtBus{
  height: 40px ;
  padding-top: 5px;

}
.topMrg{
  margin-top: 10px !important;
}
.datePickerDeal-chld {
  width: 100% !important;
  z-index: 9999 !important;
  border: none !important;
  background-color: #6a4b9a !important;
  // height: 60px;
  height: 45px !important;
border-radius: 5px !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  z-index: 999 !important;
font-size: 14px !important;

  ::placeholder{
font-size: 14px !important;

  }
}
.inputHoliday{
  background-color: #6a4b9a !important; 
  border: none !important;
  height: 45px !important;
  border-radius: 5px !important;
  font-size: 14px !important;

}
.bg {
  // background-image: url("../../../Assets/img/image-01.png");
  background-image: url("../../../Assets/img/background2.jpg");
/*   height: 950px; */
  height: 153vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: 1;
  top: -53px;
  bottom: 0;
  left: 0;
  right: 0;
}

.menuItem {
  color: $yellowEvents;
}

.downContent{
  position: relative;
  z-index:2;
  margin-top:100px ;
}

// .menuItem :active {
//   color: $yellowEvents;
//   background-color: $iconsColor;
// }

.border {
  border-width: 1px;
  border-style: solid;
  border-color: red;
}




.name {
  font-weight: 500;
}

.messageBar {
  margin-left: 1px !important;
  margin-right: 1px !important;
  background-color: $blueevents;
}

.iWantIn {
  background-color: silver;
}

.buttonItem {
  color: white;
  background-color: $blueevents;
  border: none !important;

  &:focus {
    color: $details;
    // border-radius: 15px !important;
    // border: none !important;
    // box-shadow: none !important;
    // color: $purpleColor;
    font-weight: 800;
  }

  &:active {
    // background-color: $iconsColor;
    // border-radius: 15px !important;
    // border: none !important;
    // box-shadow: none !important;
    color: $details;
    font-weight: 800;
  }
}

.imageBox {
  height: 50px !important;
  width: 50px !important;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  background-color: white;
}

.imageBoxModal {
  width: 100px !important;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  background-color: white;
}

.modal {
  background: rgba(255, 255, 255, 0.4);
}

.follow {
  background-color: $followColor;
  color: white;
  font-size: 9px;
}

.report {
  background-color: $reportColor;
  color: white;
  font-size: 9px;
}

.seeprofile {
  background-color: $seeProfileColor;
  color: white;
  font-size: 9px;
}

.categorii {
  color: $followColor;
}



.modalMakeEvt{

  .greenBtn{
    background-color: #fac227 !important;
    color: white;
    border-radius: 50px;
    font-size: 18px!important;
    cursor: pointer;
    transition: 0.3s;
    border:none!important;
 

    &:hover{
      background-color: #daa003 !important;
     
    }
  }


  .centerGreenBtn{
    border-left:2px solid #b48400 !important;
    border-right: 2px solid #b48400 !important;
  }


}




@media screen and (max-device-width : 767px){

  .busUsr{

    .makeEventRow .makeEventBtn {
      // margin-top: 5px !important;
      // margin-top: -20px !important;
  }
  

  }

  .modalMakeEvt{

    .greenBtn{
            font-size:14px!important;
    }
  }

  .findPeopleContainer {
    min-height: 500px !important;
  }

}

.cardsListContainer{
  padding:10px 0px!important;
  cursor:pointer;
}

.holidayContainer {
  background: #8982bb!important;
  border-radius: 25px;
  border: 2px solid #fff;
  padding: 15px!important;
  margin-left: 20px;
  margin-right: 20px ;
  margin-top: 10px !important;
  -webkit-box-shadow: 6px 6px 14px 3px rgba(0,0,0,0.21);
  -moz-box-shadow: 6px 6px 14px 3px rgba(0,0,0,0.21);
  box-shadow: 6px 6px 14px 3px rgba(0,0,0,0.21);
  // width: 100% ;
  justify-content: center !important;
  align-items: center !important;
  align-self:  center !important;
  .holidayTitle {
    font-size: 24px;
    font-weight: bold;
    color: $details;
  }

  .greenBtn {
    background-color: #fac227 !important;
    color: white;
    border-radius: 50px;
    font-family: "CircularAirBold";
    font-size: 18px !important;
    cursor: pointer;
    transition: 0.3s;
    border: none !important;
    text-transform: capitalize !important;
    border-radius: 50px !important;
    padding: 8px 35px !important;

    &:hover {
      background-color: #d69d00 !important;
    }
  }
}


.appBtn {

/*   margin-bottom: 20px;
  margin-top: 20px; */
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    transform: scale(1.05);
  }
}

.div-search {
  align-self: flex-start !important;
}
.voucherContainer {
  padding: 8px;
  z-index: 0 !important;
  border-radius: 15px;
  background-color: white !important;
  -webkit-box-shadow: 6px 6px 28px -6px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: 6px 6px 28px -6px rgba(0, 0, 0, 0.47);
  box-shadow: 6px 6px 28px -6px rgba(0, 0, 0, 0.47);
  margin: 10px 0px;

  .centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.myProfileImage {
  cursor: pointer;
  max-width: 80px !important;
  height: 80px !important;
  margin: 0 auto;
  padding: 8px;
  width: 100% !important;
  border-radius: 50%;
  object-fit: cover;
}

.approve_green {
  margin-top: 10px;
  background-color: $details;
  border-color: $details;
}

.reject_red {
  margin-top: 10px;
  background-color: $reportColor;
  border-color: $reportColor;
}

.approve_green_text {
  color: $details;
}

.reject_red_text {
  color: $reportColor;
}
.see_event_blue_text{
  width: 100%;
  color: $blueevents;
  cursor: pointer;
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
}

.paddingVoucherSearch {
  padding-right: 12px !important;
  padding-left: 12px !important;
}
@media screen and (max-device-width: 1440px) {
  .bg{
    height: 200vh;
  }
}
@media screen and (max-device-width: 1280px) {


.bg{
  height: 200vh;
}
}
