
.arrowParent{
    position:fixed;
    right:20px;
    top: calc(100vh - 100px);
    z-index:99;
    display:flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;


    &:hover{
        transform: scale(1.1);
    }

    .Arr{
        font-size:20px;
        color:#093d77;
        padding: 15px 22px;
        background:#fff;
        border-radius:50%;
        -webkit-box-shadow: 10px 10px 66px -11px rgba(0,0,0,1);
        -moz-box-shadow: 10px 10px 66px -11px rgba(0,0,0,1);
        box-shadow: 10px 10px 66px -11px rgba(0,0,0,1);
        transition: 0.3s;

        &:hover{
            color:#13e567;
        }
    }
}

.arrowParentMobile {
  left: calc(100vw - 80px);
  position: fixed;
  top: calc(100vh - 100px);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    transform: scale(1.1);
  }

  .Arr {
    font-size: 20px;
    color: #093d77;
    padding: 15px 22px;
    background: #fff;
    border-radius: 50%;
    -webkit-box-shadow: 10px 10px 66px -11px rgba(0, 0, 0, 1);
    -moz-box-shadow: 10px 10px 66px -11px rgba(0, 0, 0, 1);
    box-shadow: 10px 10px 66px -11px rgba(0, 0, 0, 1);
    transition: 0.3s;

    &:hover {
      color: #13e567;
    }
  }
}
.h4-text{
    text-align: center !important;
margin-bottom: 20px !important;
}
.modal-body{
    .cllose-btn{
        border: solid black !important;
        text-align: right  !important;
        padding: 20px !important ;
    }
    .modal-body-child{
        padding: 50px 50px 1px 50px  !important;
    }
    .accept-cookies-parent{
        align-self: center !important;
    .accept-cookies-btn{
        border: solid rgb(0, 94, 172) ;
        background-color: rgb(0, 94, 172)  !important;
        width: 300px !important;
        height: 60px !important;
        margin-bottom: 40px !important;
        font-weight: 600 !important;
        &:hover{
        background-color: white !important;
        color: rgb(0, 94, 172) !important;
        }
    }

}
}






.FirstPage-Footer{
    background: url('../../Assets/img/footerBg.svg') no-repeat;
    background-size: cover;
    position: relative;
    bottom:0;
    height: 300px;
    margin-top: 250px;
    z-index: 1 !important;

}

.FirstPage-Footer{
margin-top: 100px;
}
@media screen
and (min-device-width : 1921px)
and (max-device-width : 3000px)
{


.FirstPage-Footer {
    margin-top: 865px;
}

}
@media screen
and (max-device-width : 1680px)
{
.FirstPage-Footer{
    margin-top: 195px;
}


}
@media screen
and (max-device-width : 1500px)
{

.FirstPage-Footer {
    margin-top: 190px;
    height: 300px;
}

}
@media screen
and (max-device-width : 1280px)
{
.FirstPage-Footer{
    margin-top: 222px;
    height: 259px;
}




}
@media screen
and (max-device-width : 1024px)
{



.FirstPage-Footer {
    height: 217px;
    margin-top: 146px;
}


}
@media screen
and (max-device-width : 768px)
{


.FirstPage-Footer {
    // height: 155px;
    margin-top: 40px;
    margin-bottom: 100px !important;
    // padding-bottom: 80px !important;s
}



}
@media screen
and (max-device-width : 375px)
{
.FirstPage-Footer {
margin-bottom: 0 !important;
height: 130px !important;
}


}
@media screen
and (max-device-width : 414px)
{
.FirstPage-Footer {
margin-bottom: 0 !important;
height: 153px;
}


}
