@import "../../../Assets/css/variable";

$titleColor:#083d77!important;

.ourPartnersTitle{
    
    font-size: 60px;
    text-align: center;
    padding: 80px 0 50px;
    width: 100%;
    position: relative;
    top: 0;
    background: linear-gradient(115deg, #3c1053 47%, #ad5389 59%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: -2px;

}

.ourPartnersTitle b{
    font-family: $fontBold;
}


.ourPartners{
    background: url('../../../Assets/img/aboutus.png') no-repeat;
    background-position: bottom;
    margin: 0 auto;
/*     margin-top: -100%; */
    position: relative;
    // z-index: 9;
    height: 1360px;
    bottom: -392px;
}
  

.ourParent{
    overflow-y: auto;
    max-height: 400px !important;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}


.partners{
    width: 20%;
    height: 140px;
    margin: 5px !important;
    padding: 80px ;
    background: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 43px 118.59px 58.41px rgba(29, 33, 33, 0.17);
 
      

    img{
        max-width: 100%;
        max-height:120px;
        object-fit: cover;
    }
}



@media screen 
and (min-device-width : 1921px) 
and (max-device-width : 3000px) 
{ 
    .ourPartners{
        margin-top: -77%;
    }

}




@media screen 
and (max-device-width : 1440px) 
{ 
    .ourPartners {
        bottom: -200px;
    }

    .ourPartnersTitle {
        font-size: 67px;
    }

  
}

@media screen 
and (max-device-width : 1280px) 
{ 
    .ourPartners{
        margin-top: -69px;
    }

    .ourParent{

        margin-bottom:50px;
    }

}




@media screen 
and (max-device-width : 1024px) 
{ 
    .ourPartnersTitle {
        font-size: 64px;
    }

    .ourPartners {

    }

    .partners{
        width: 45%;
        margin:10px;
    }

}

@media screen 
and (max-device-width : 1024px) and (orientation : landscape){

    .partners img {
        max-height: 82px;
    }
}




@media screen 
and (max-device-width : 767px){
    .ourPartnersTitle {
        font-size: 35px;
       padding: 18px 0 22px;
    }


    .ourPartners {
        margin-top: -522px;
        background-position: top;
        height: 1149px;
    }
    

    .ourParent{
        flex-wrap: wrap;
    }

    .partners {
        margin-bottom: 0;
        width: 290px;
        padding: 10px;
        height: 90px;
    }

    .partners img {

        max-height: 60px;
    }



  

    

}



@media screen 
and (max-device-width : 736px) and (orientation : landscape){



}

@media screen 
and (max-device-width : 375px){

}

