/*Components\NormalUser\HomeScreenNormal\HomeScreenNormal.scss*/
$blueevents: #4e178c !important;
$yellowEvents: #f4d35e !important;
$greenEvents: #13e567 !important;
$iconsColor: #0ee6e0 !important;
$purpleColor: #4b0082 !important;
$followColor: magenta !important;
$seeProfileColor: #13e567 !important;
$reportColor: red !important;
$details: #fac227 !important;
$btnCol: #fac227 !important;

@import "../../../Assets/css/variable";

body,
html {
  height: 100%;
}

.bg {
  // background-image: url("../../../Assets/img/background.jpg");
  // height: 54.5vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // border-bottom-left-radius: 1000px 900px;
  // border-bottom-right-radius: 9000px 700px;
  max-width: 100% !important;


 
}
// .divHeightFriends{
//   height: 750px !important;
// }
.container{
  width: 100% !important;
}
.menuBarN {
  background-color: $blueevents;
  color: white;
  border-radius: 10px !important;
  border-width: 2px;
  border-style: solid;
  border-color: seashell;
  -webkit-box-shadow: 6px 6px 14px 3px rgba(0,0,0,0.21);
  -moz-box-shadow: 6px 6px 14px 3px rgba(0,0,0,0.21);
  box-shadow: 6px 6px 14px 3px rgba(0,0,0,0.21);
  justify-content: flex-start !important;
  padding-left: 15px !important;

  div{
    padding-left: 15px !important;
    font-size:19px;
    font-family: $fontBold;
    cursor: pointer;
    transition: 0.2s;
  }

  div:hover{
    color:#ebac00;
  }
}

.menuItem {
  color: $yellowEvents;
}

// .menuItem :active {
//   color: $yellowEvents;
//   background-color: $iconsColor;
// }

.border {
  border-width: 1px;
  border-style: solid;
  border-color: red;
}

.search {
  width: 100%;
}

.event {
  background: rgba(255, 255, 255, 0.4);
}


.addCardBtn {
  background-color: #20b55c !important;
  color: white;
  border-radius: 50px !important;
  font-size: 25px;
  padding-left: 30px;
  padding-right: 30px;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  border: none !important;

  &:hover {
    background-color: #19773f !important;
  }
}
.name {
  font-weight: 500;
}

.description {
  background-color: seashell;
}

.messageBar {
  color: white;
  margin-left: 1px !important;
  margin-right: 1px !important;
  background-color: $blueevents;
}

.searchButton {
  height: 38px;
}

.iWantIn {
  background-color: silver;
}

.buttonItem {
  color: white;
  background-color: $blueevents;
  border: none !important;

  &:focus {
    color: $details;
    // border-radius: 15px !important;
    // border: none !important;
    // box-shadow: none !important;
    // color: $purpleColor;
    font-weight: 800;

    outline: 0 !important;
    outline-offset: 0 !important;

    box-shadow: none !important;
  }

  &:active {
    // background-color: $iconsColor;
    // border-radius: 15px !important;
    // border: none !important;
    // box-shadow: none !important;
    color: $details;
    font-weight: 800;
  }
}

.permanentGreen {
  color: white;
  background-color: $blueevents;
  border: none !important;
}

.permanentGreen {
  color: $details;
  background-color: $blueevents;
  border: none !important;
}
.parentBoth{
  justify-content: flex-end ;
  width: 100% !important;
  flex-wrap:nowrap !important;
}
.makeEventBtn-mine {
  margin: 0 2px 0 2px;
  text-align: center !important;
  width: 50% !important;
  background-color: $btnCol;
  color: #492ec4 !important;
  border-radius: 10px;
  font-family: $fontBold;
  font-size:17px;
  -webkit-box-shadow: 3px 3px 36px 5px rgba(0,0,0,0.13);
  -moz-box-shadow: 3px 3px 36px 5px rgba(0,0,0,0.13);
  box-shadow: 3px 3px 36px 5px rgba(0,0,0,0.13);
  cursor: pointer;
  transition: 0.3s;

    &:hover{
      background-color:#ebac00!important;
    }

  
}


.makeEventBtn {
  background-color: $btnCol;
  color: #492ec4 !important;
  border-radius: 10px;
  font-family: $fontBold;
  font-size:17px;
  -webkit-box-shadow: 3px 3px 36px 5px rgba(0,0,0,0.13);
  -moz-box-shadow: 3px 3px 36px 5px rgba(0,0,0,0.13);
  box-shadow: 3px 3px 36px 5px rgba(0,0,0,0.13);
  cursor: pointer;
  transition: 0.3s;
  min-width: 150px;

    &:hover{
      background-color:#ebac00!important;
    }

  
}

.imageBox {
  height: 50px !important;
  width: 50px !important;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  background-color: white;
}

.imageBoxModal {
  width: 100px !important;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  background-color: white;
}

.modal {
  background: rgba(255, 255, 255, 0.4);
}

.follow {
  background-color: $followColor;
  color: white;
  font-size: 9px;
}

.report {
  background-color: $reportColor;
  color: white;
  font-size: 9px;
}

.seeprofile {
  background-color: $seeProfileColor;
  color: white;
  font-size: 9px;
}

.categorii {
  color: $followColor;
}
.imageBoxFriends {
  height: 50px !important;
  width: 50px !important;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  background-color: white;
}
.container-height {
  // background-color: blueviolet !important;
  height: 50px !important;
  max-height: 50px !important;
  min-height: 50px !important;
}
.my-1-costume{
  // margin: 10px !important;
  margin: 0 25px  20px  0 !important;
  padding-bottom: 10px !important;
}
.makeEventRow{
  margin-bottom: 18px;
}




@media screen
and (max-device-width : 1500px)
{
  .lgUsr .container{
    width:100%!important;
  }

}




@media screen
and (max-device-width : 1300px)
{
  .menuBarN div {
    font-size: 19px;
  }
}


@media screen and (max-device-width : 1024px){
  .eventProfilePic {
    height: 50px!important;
    width: 50px!important;
  }

}

@media screen and (max-device-width : 414px){
  .businessOnly{
    padding-bottom: 10px;

  }
  .marginTop{
  margin-top: 30px !important;
  }
}
@media screen and (max-device-width : 375px){
  .businessOnly{
    padding-bottom: 10px;

  }
  
  .marginTop{
  margin-top: 30px !important;
  }
}
@media screen and (max-device-width : 767px){
  .scannerMrg{
    margin-bottom: 30px;
  }

  .lgUsr .container{

    width:100%!important;
  }
  .bg {
    height: 180vh!important;
/*     height: 601px !important; */

  }
    .modal-title{
      font-size:15px;
    }

  .makeEventBtn {
    font-size: 14px!important;
  }
.makeEventBtn-mine{
  font-size: 14px!important;

}
  .downContent {
    padding:0!important;
    margin-top: 50px!important;
  }

  .menuBarN div {
    font-size: 11.5px;
}

.makeEventRow{
  margin-bottom: 9px;

  .makeEventBtn {
    margin-top:0!important;
  }


}
  .locations_promotions_voucher {
    width: 100%;
    height: 50px;
    border: none;
    background-color: #2d5683 !important;
    border-radius: 8px;
    padding-right: 10px;
    margin-top:10px;
    margin-bottom: 10px;
  }

.cardDetailsParent {
  .cardImg {
    margin-right: 5px;
    font-family: $fontBold;
    color: #093d77;
    font-size: 30px !important;
  }

  .leftPart {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .text {
    margin-right: 5px;
    font-family: $fontBold;
    color: #093d77;
  }

    .terms {
      color: gray;
      font-size: 14px !important;
      display: flex;
      justify-content: center;
    }
  }
}
.jumbo-containerr{
  padding: 10px !important;
  margin: 0 !important;
}

.categoryInputV #combo-box-demo4 {
  border: none;
  border-radius: 15px;
  height: 20px;
  padding-left: 10px;
}
.locationNoVoucher{
  margin-left: 20px;
}
.categoryInputB {
  border-radius: 15px;
  background-color: #2d5683 !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
}
.voucherSearch{
  margin-top:10px;
  margin-bottom: 25px;
  justify-content: space-between;
}
.searchContainer {

  background-color: $details;
  height: 50px !important;
  width: 50px !important;
  max-width: 50px !important;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: #f3b60d !important;
  }
}
