/*Components\BothUsers\Events\ContentEvents.scss*/
$blueevents: #446cdb !important;
$yellowEvents: #f4d35e !important;
$greenEvents: #00b4b1 !important;
$iconsColor: #0ee6e0 !important;
$purpleColor: #4b0082 !important;
$followColor: magenta !important;
$seeProfileColor:#3dcab7  !important;
$reportColor: red !important;
$details: #fac227 !important;
$radius: 15px;

@import "../../../Assets/css/variable";

body,
html {
  height: 100%;
}
.noPadding {
  padding-left: 0 !important;
}

.colPosition{
  // position: sticky !important;
  top: 30px;
}
.classAth{
  z-index: 1000000 !important;
  position: relative;

}
.posFake{
  bottom: 0;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute !important;
  width: 100% !important;
}
.absolute-btns{
  position: relative;
}
.parentButtons{
  width: 100%;
  height: 50px;
  position: absolute;
  top: -10px;
  right: 0 !important;
}
.parentButtonsProm{
  width: 100%;
  height: 50px;
  position: absolute;
  top: 37px;
  right: 0 !important;
}
.bg {
  background-image: url("../../../Assets/img/image-01.png");
  height: 54.5vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

// .eventContainer {
// }

.border {
  border-width: 1px;
  border-style: solid;
  border-color: red;
}

.event {
  background: rgba(255, 255, 255, 0.4);
}

.sponsoredBy {
  font-family: $fontBold;
  font-size: 12px;
  //cursor: pointer;
}

.seeVideo {
  cursor: pointer;
  font-family: $fontBold;
  font-size: 12px !important;
}

.promotionBanner {
  background: #870066;
  width: 100%;
  margin-top: -8px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: auto;
  padding: 10px;
  text-align: center;
  color: white;
}

.eventProfilePic {
  height: 70px !important;
  width: 70px !important;
  max-width: 70px !important;
  border-radius: 50px;
  cursor: pointer;
  border: none;

  object-fit: cover;
}


.evtPic {
  margin-top: -22px;
}

.MessageBtn {
  background: #fac227 !important;
  border: none !important;
  border-radius: 50% !important;
  font-size: 20px !important;
  margin-left: -17px;
}

.bottomMessages {
  width: 100%;
  padding-top: 15px;
  background-color: #2d5683 !important;
  display: flex;
}

.seeAllMessages {
  background-color: #20b55c !important;
  color: white;
  padding: 7px 30px 7px 30px;
  max-width: 150px;
  border-radius: 50px;
  font-family: "CircularAirBold";
  font-size: 14px;
  -webkit-box-shadow: 3px 3px 36px 5px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 3px 3px 36px 5px rgba(0, 0, 0, 0.13);
  box-shadow: 3px 3px 36px 5px rgba(0, 0, 0, 0.13);
  cursor: pointer;
  transition: 0.3s;
  margin: 0 0 0 50px;

  &:hover {
    background-color: #1b9a4e !important;
  }
}

.bottomMessageText {
  color: white;
  margin: 0 !important;
}

.closeEventBtn {
  background: transparent !important;
  // border: none !important;
  color: #4e178c !important;
  font-size: 23px !important;
  transition: 0.2s !important;
  position: absolute;
  z-index: 2;
  right: 0;
  margin: 5px;
top: 0;
border:none !important;
  &:hover {
    transform: scale(1.08);
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}


.Close-pen-btn {
  background: transparent !important;
  // border: none !important;
  color: #4e178c !important;
  font-size: 20px !important;
  transition: 0.2s !important;
  position: absolute;
  z-index: 2;
  right: 40px;
  margin: 5px;
top: 0 !important;
border:none !important;
  &:hover {
    transform: scale(1.08);
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}
.classReadMore{
  z-index: 10000 !important;
}

.description {
  background-color: white !important;
  border-radius: 15px;
  color: #2d5683;
  border: 1px solid #959595;
  cursor: pointer;
  font-family: $fontNormal;
  padding: 10px;
z-index: 0 !important;
}

.details {
  background-color: #61278f;
  color: white;
  border-radius: 5px;
  font-size: 15px;
  font-family: $fontBold;
  padding: 5px 25px 5px 25px;
  text-align: center;
  margin-right: 15px;
  margin-bottom: 10px;

}

.detailsBoxs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 15px;
}

.messageBar {
  color: white;
  margin-left: 1px !important;
  margin-right: 1px !important;
  background-color: $blueevents;
}

.searchButton {
  height: 38px;
}

.closed {
  background-color: #aeb0b2;
  border-radius: 5px;
  color: #2c5481;
  font-family: $fontBold;
  text-transform: capitalize;
  font-size: 20px !important;
}

.iWantIn {
  background-color: silver;
}

.imageBox {
  height: 50px !important;
  width: 50px !important;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  background-color: white;
}

.imageBoxModal {
  width: 100px !important;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  background-color: white;
}

.modal {
  background: rgba(255, 255, 255, 0.4);
}

.follow {
  // background-color: $followColor;
  color: white;
  font-size: 9px;
}

.report {
  background-color: $reportColor;
  color: white;
  font-size: 9px;
}

.seeprofile {
  background-color: $seeProfileColor;
  color: white;
  font-size: 9px;
}

.categorii {
  color: $followColor;
}

.imageBoxFriends {
  height: 50px !important;
  width: 50px !important;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  background-color: white;
}

.searchImg {
  // background-color: red !important;
  filter:blur() !important;
  height: 25px !important;
  filter: invert(100);
}

.searchContainer {
  background-color: #fac227;
  height: 50px !important;
  width: 50px !important;
  max-width: 50px !important;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: #dda200 !important;
  }
}

.borderSearchBar {
  border-radius: $radius;
  background-color: #fff;
}

.modalZ {
  z-index: 9999 !important;
}

.eventContainerTop {
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
  background-color: white !important;
}

.eventContainerBottom {
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
  background-color: $blueevents;
  height: 80px;

}

.bottomTextInput {
  margin-top: 13px;
}

.messageDot {
  background-color: $details;
  border-radius: 50%;
  color: white;
  font-weight: 700;
  height: 48px;
  width: 48px !important;
  max-width: 50px !important;
}

.eventsArea {

  height: 709px !important;
  overflow: auto;
  overflow-x: hidden;
  z-index: 9000 !important;
  padding-right: 30px;


  .alert {
    
    margin-top: 20px;
    display: flex;
    justify-content: center;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 15px;
    text-align: center;
    color: #fff;
    font-size: 20px;
  }
}

.eventsArea::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #76f5bc;
}

.eventsArea::-webkit-scrollbar {
  border-radius: 5px;
  width: 5px;
  background-color: #fff;
}

.eventsArea::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #093d77;
}

.each-events {
  border-radius: $radius;
  // border: solid 2px rgb(196, 32, 32);
  -webkit-box-shadow: 6px 6px 28px -6px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: 6px 6px 28px -6px rgba(0, 0, 0, 0.47);
  box-shadow: 6px 6px 28px -6px rgba(0, 0, 0, 0.47);

}
.haha{
  cursor: pointer;
}
.nameAndCategory {
  font-family: $fontBold;
  font-size: 22px;
  color: #4e178c;
  margin-top: 6px;
  margin-bottom: 13px;
  background-color: rgb(255, 255, 255) !important;
}

.joinButton {
  background-color: #4e178c !important;
  width: 100% !important;
  font-size: 17px !important;
  border: none !important;
  border-radius: 10px !important;
  justify-content: center;
  animation: pulse 2s infinite;


  &:hover {
    background: #6a4b9a !important;
  }
}

.voucherButton {
  background-color: #1fb6fe !important;
  width: 100% !important;
  
  font-size: 14px !important;
  border: none !important;
  border-radius: 10px !important;
  justify-content: center;
  animation: pulse 2s infinite;
  margin-top: 10px ;
  &:hover {
    background: #188bc1 !important;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(31, 182, 254, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(31, 182, 254, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(31, 182, 254, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(31, 182, 254, 0.6);
    box-shadow: 0 0 0 0 rgba(31, 182, 254, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(31, 182, 254, 0);
    box-shadow: 0 0 0 10px rgba(31, 182, 254, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(31, 182, 254, 0);
    box-shadow: 0 0 0 0 rgba(31, 182, 254, 0);
  }
}

.messageInput {
  border-radius: 12px !important;
  font-family: $fontBold;
  color: #2d5683 !important;
  font-size: 15px !important;
}

.eventOpenClosed {
  font-size: 17px;
}


.btnStatusEv {
  width: 35%;
  text-align: center;
  font-size: 15px !important;
  font-family: $fontNormal;

}
.dot {
  height: 10px;
  width: 10px;
  background-color: #00ff48;
  border-radius: 50%;
  display: inline-block;
}
.dot-red{
  height: 10px;
  width: 10px;
  background-color: #ff0000;
  border-radius: 50%;
  display: inline-block;
}
.btnStatusEv.open {
  background: #ffffff !important;
  margin-top: 8px;

  border-radius: 15px;
  color: #65317d;
  font-weight: bold;
}

.btnStatusEv.closed {
  // padding-top: 10px !important;
  margin-top: 8px;
  background: #ffffff !important;
  border-radius: 15px;
  color: #65317d;

  font-weight: bold;
}
.EventLabel-content{
  color: #fff;
}
.promoteEvtBtn {
  display: flex !important;
  width: 100%;
  border-radius: .25rem;
  justify-content: center;
  font-size: 16px !important;
  background: $details;
  border: none !important;
  transition: 0.3s !important;
  align-items: center;

  &:hover {
    background: $details;
  }
}

.payButton {
  display: flex !important;
  width: 100%;

  border-radius: 10px !important;
  justify-content: center;
  margin-top: 20px !important;
  font-size: 16px !important;
  background: #6a4b9a !important;
  border: none !important;
  transition: 0.3s !important;
  align-items: center;

  &:hover {
    background-color: #4e178c !important;
  }
}


.payButton.free {
  background: #6a4b9a !important;

  pointer-events: none !important;
}

.messageInput::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #2d5683 !important;
}

.messageInput::-moz-placeholder { /* Firefox 19+ */
  color: #2d5683 !important;
}

.messageInput:-ms-input-placeholder { /* IE 10+ */
  color: #2d5683 !important;
}

.messageInput:-moz-placeholder { /* Firefox 18- */
  color: #2d5683 !important;
}


.parent-btn-grup {
  margin-top: 55px !important;
  margin-bottom: 20px !important;
}


@media screen and (max-device-width: 1600px) {

  .details {
    font-size: 14px;
    margin-right: 5px;
    padding: 5px 20px 5px 20px;
    font-family: $fontNormal;
  }


  .nameAndCategory {
    font-size: 20px;
  }

  .closed, .joinButton {
    font-size: 14px !important;
  }

  .description {
    font-size: 14px;
  }
  .eventProfilePic {
    height: 60px !important;
    width: 60px !important;
    max-width: 60px !important;
    margin-top: -21px !important;
    object-fit: cover !important;
  }
  .eventProfilePic-MakeEvents{
    height: 60px !important;
    width: 60px !important;
    max-width: 60px !important;
    object-fit: cover !important;
  }

  @media screen and (min-device-width: 1921px) and (max-device-width: 3000px) {
    .eventsArea {
      height: 100vh !important;
    }

  }

  @media screen and (max-device-width: 1300px) {
    .details {
      padding: 5px 10px 5px 10px;
    }

    .eventOpenClosed {
      font-size: 14px;
    }

    .btnStatusEv {
      font-size: 13px !important;
    }

  }


}


@media screen and (max-device-width: 1024px) {
  .details {
    font-size: 11px;
  }

  .btnStatusEv {
    width: 50%;
  }

  .eventOpenClosed {
    display: none;
  }

  .eventsArea {
    height: 297px !important;
    padding-right: 5px !important;
  }


  .eventProfilePic {
    height: 50px !important;
    width: 50px !important;
    max-width: 50px !important;
    margin-top: 2px !important;
  }


}

@media screen and (max-device-width: 375px) {
  .messageInput::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #2d5683 !important;
    font-size: 11px !important;
  }
  
  .messageInput::-moz-placeholder { /* Firefox 19+ */
    color: #2d5683 !important;
    font-size: 11px !important;
  }
  
  .messageInput:-ms-input-placeholder { /* IE 10+ */
    color: #2d5683 !important;
    font-size: 11px !important;
  }
  
  .messageInput:-moz-placeholder { /* Firefox 18- */
    color: #2d5683 !important;
    font-size: 11px !important;
  }
  // .edtButtons {
  //   // top: -300px !important;
  //   left: -20px !important;
  //   bottom: 290px !important;

  // }
  .categText {
    left: -35px !important;
    font-size: 5px !important;
    line-height: 16px;
  }
}
@media screen and (max-device-width: 414px) {
  // .edtButtons {

  //   top: -240px !important;
  //   left: -20px !important;

  // }
  .categText {
    left: -35px !important;
    font-size: 5px !important;
    line-height: 16px;
  }
}

@media screen and (max-device-width: 767px) {
  .joinShit{
    padding-left: 5px !important;
    padding-right: -20px !important;
  }
  .promoteEvtBtn {
    display: flex !important;
    width: 100%;
    border-radius: 12px !important;
    justify-content: center;
    font-size: 12px !important;
    background: $details;
    border: none !important;
    transition: 0.3s !important;
    align-items: center;
  
    &:hover {
      background: $details;
    }
  }
  .closeEventBtn {
    background: transparent !important;
    // border: none !important;
    color: #4e178c !important;
    font-size: 18px !important;
    transition: 0.2s !important;
    position: absolute;
    z-index: 2;
    right: 0;
    margin: 5px;
  top: 0;
  padding: 5px !important;
  border:none !important;
    &:hover {
      transform: scale(1.08);
    }
  
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }
  
  
  .Close-pen-btn {
    padding: 5px !important;
    background: transparent !important;
    // border: none !important;
    color: #4e178c !important;
    font-size: 15px !important;
    transition: 0.2s !important;
    position: absolute;
    z-index: 2;
    right: 40px;
    margin: 5px;
  top: 0 !important;
  border:none !important;
    &:hover {
      transform: scale(1.08);
    }
  
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .colPosition{
    position: sticky !important;
    // border: solid black;
    display: flex !important;
  }
  .joinButton {
    background-color: #4e178c !important;
    width: 100% !important;
    font-size: 12px !important;
    border: none !important;
    border-radius: 10px !important;
    justify-content: center;
    animation: pulse 2s infinite;
  
  
    &:hover {
      background: #6a4b9a !important;
    }
  }
  .voucherButton {
    background-color: #1fb6fe !important;
    width: 100% ;
    max-width: 120px !important;
    
    font-size: 12px !important;
    border-radius: 10px !important;
    justify-content: center;
    animation: pulse 2s infinite;
    margin-top: 0 !important;
    &:hover {
      background: #188bc1 !important;
    }
  }
  .haha{
    left: 5px;
    bottom: 5px;
  }
  // .edtButtons {
  //   top: -34px;
  //   right: -15px;

  // }

  .eventsArea {
    margin-top: -6px;
    height: 370px !important;
  }

  .eventProfilePic {
    height: 35px !important;
    width: 35px !important;
    max-width: 35px !important;

  }

  .eventContainerTop .mobMargin {
    // margin-top: -26px !important;
  }

  .searchContainer {
    height: 35px !important;
    width: 35px !important;
    max-width: 35px !important;

    img {
      height: 17px !important;
    }
  }

  .categText {
    font-size: 14px !important;
    line-height: 16px;
  }

  .bottomTextInput {
    margin-top: 7px;
  }

  .eventContainerBottom {
    height: 68px;
  }

  .detailsBoxs {
    margin-left: -8px;
    margin-top: 9px;
    margin-bottom: 0;
  }

  .btnStatusEv {
    width: 100%;
  }

  .parent-btn-grup {
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    // border: solid black;
    height: 100% !important;
  }

  .payButton {
    margin-top: 0 !important;
  }

  .messageDot {
    height: 40px;
    width: 40px !important;
    max-width: 40px !important;
  }

  .messageInput {
    font-size: 15px !important;
  }

  .sponsoredBy {
    font-size: 0px;
  }
  .seeVideo {
    font-size: 12px;
  }
}

.costume-star-btn2 {
  background-color: transparent !important;
  color: #0047cc !important;
  border: none !important;
  margin-bottom: 8px !important;
  padding: 2px 2px 2px 5px !important;
}

.costume-star-btn3 {
  background-color: transparent !important;
  color: #00c40a !important;
  margin-bottom: 8px !important;
  border: none !important;
  padding: 2px 2px 2px 5px !important;
}
