@font-face {font-family: "CircularAirLight";
  src: url("//db.onlinewebfonts.com/t/809eb889c78777517a6d252fd1f76eac.eot");
  src: url("//db.onlinewebfonts.com/t/809eb889c78777517a6d252fd1f76eac.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/809eb889c78777517a6d252fd1f76eac.woff2") format("woff2"),
  url("//db.onlinewebfonts.com/t/809eb889c78777517a6d252fd1f76eac.woff") format("woff"),
  url("//db.onlinewebfonts.com/t/809eb889c78777517a6d252fd1f76eac.ttf") format("truetype"),
  url("//db.onlinewebfonts.com/t/809eb889c78777517a6d252fd1f76eac.svg#Circular Air Light") format("svg"); }


@font-face {font-family: "CircularAirBold"; src: url("//db.onlinewebfonts.com/t/6cf0ce88e2fb8e3c53cff722a765a50c.eot"); src: url("//db.onlinewebfonts.com/t/6cf0ce88e2fb8e3c53cff722a765a50c.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/6cf0ce88e2fb8e3c53cff722a765a50c.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/6cf0ce88e2fb8e3c53cff722a765a50c.woff") format("woff"), url("//db.onlinewebfonts.com/t/6cf0ce88e2fb8e3c53cff722a765a50c.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/6cf0ce88e2fb8e3c53cff722a765a50c.svg#Circular Air Bold") format("svg"); }

@font-face {font-family: "CircularAirBook"; src: url("//db.onlinewebfonts.com/t/bd6213fa49bedc8cb050c63f14360efa.eot"); src: url("//db.onlinewebfonts.com/t/bd6213fa49bedc8cb050c63f14360efa.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/bd6213fa49bedc8cb050c63f14360efa.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/bd6213fa49bedc8cb050c63f14360efa.woff") format("woff"), url("//db.onlinewebfonts.com/t/bd6213fa49bedc8cb050c63f14360efa.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/bd6213fa49bedc8cb050c63f14360efa.svg#Circular Air Book") format("svg"); }



body {
  margin: 0;
  font-family: 'CircularAirBook', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw!important;
  height: 100%;  padding: 0;
  overflow: hidden !important; 
  overflow-Y: auto!important;
}

.noOverflow{
  overflow: hidden!important;
  overflow-Y: auto!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.load{
  background: #837edf;
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height:100vh;
  overflow: hidden;
  transition: 1s;
  right: 0;
  top:0;
  bottom:0;
  left:0;
}





.load .line-scale-pulse-out > div {
  background:linear-gradient(115deg, #482ec6 2%, #9355da 83%);
  width: 10px;
  height: 230px;
  border-radius: 10px;
  margin: 10px;
}


.loader-inner{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.swal-title{
  font-size: 21px;
}
.swal-icon{
  margin:0px auto;
  width: 40px;
    height: 40px;
}

.swal-icon--warning__body{
  height: 20px;
  top:5px;
}
.swal-icon--error__line{
  width: 28px;
  top: 18px;
}
.swal-icon--error__line--left{
  left:6px;
}
.swal-icon--error__line--right{
  right:6px;
}
.swal-icon--info:before{
  bottom: 3px;
  height: 26px;
}

.swal-icon--info:after{
  top:2px;
}
.swal-button{
  background-color: #1fb6fe!important;
  border-radius:50px;
  transition:0.3s;
  padding: 7px 30px!important;
}
.swal-button:hover{
  background-color: #188bc1!important;
}
.swal-footer{
  text-align: center;
}
.swal-icon--success{
  width:80px!important;
  height: 80px!important;
}
.rbc-btn-group{
  color: #ffffff!important;
  margin: 3px!important;
}
.rbc-btn-group button{
  color:#ffffff!important;
  background-color: #6a4b9a !important;
  margin: 3px !important;
  border-radius: 30px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.rbc-btn-group button:hover{
  background-color: #e0a500 !important;
}
button.rbc-active{
  background-color: #fac227 !important;
}
button.rbc-active:hover{
  background-color: #e0a500!important;
}
.rbc-calendar{
  border-radius: 15px!important;
  min-height: 600px !important;
}
.rbc-toolbar{
  background-color: #4e178c !important;
  color: #ffffff;
  border-top-left-radius: 15px!important;
    border-top-right-radius: 15px!important;
}
.imageOpacity{
  opacity: 0.6;
}
.businessOnly{
  color:#ffffff!important;
}
.dealExpl{
  text-align: left;
}
.eventsbottom .zingMapComponent .autocomplete-dropdown-container{
position: absolute!important;
z-index: 9999!important;
}
.switchAccount{
  color:#2d5683 !important;
  cursor: pointer;
  font-weight: bold;
  font-size: 24px;
  vertical-align: middle;;
}
.approveRejectDeals{
  width:25px;
  height:25px;
  float:right;
}
#cookie-consent-button{
  height:36px!important;
}
.EventsPicture{
  max-width: 65px;
}
.EventsPicture img{
  max-width: 65px;
}
.muliplyContainer .DatePicker {
  width:100%!important;
}
.muliplyContainer .datePicker2 {
  width:100%!important;
}

.toolTipGeneral{
    background: #ffffff !important;
    color: #042244;
    border-radius: 15px;
    padding:10px;
    margin:-15px !important

}
.iconTooltip {
  /* // border: solid black !important; */
  margin-top: 12px !important;
  margin-left: 4px;
}
.tooltipC{
  text-transform:lowercase;
}
.priceDetails{flex-wrap: wrap;}


