/*\Components\FirstPage\Footer\Footer.scss*/
@import "../../../Assets/css/variable";


$bgColor: #09b1ba;
$iconsColor: #00b4b1 !important;
$socialBg: #fff;
$textColor: #fff;

.lgUsr .Footer {
    z-index:3
    
}


.Footer{
    background: url('../../../Assets/img/bgandFooter.png') no-repeat;
    background-size: cover;
    position: relative;
    bottom:0 ;
    height: 497px;
    // z-index: 15 !important;
    
}
.drop-down-stl-contact {

    // max-width: 300px;
    // min-width: 100px;
    width: 100% !important;
    border-radius: 10px !important;
  
    background-color: #694b99 !important;
  }

  .searchBtn{
    background: #fac227!important;
justify-content: center !important;
  }
  .contactUsContainer{
      padding: 20px !important;
  }
.lgUsr  .Footer{
    margin-top: 0;
}

.lgUsr ._loading_overlay_wrapper{
    height:100vh;
}

.logoFooter {
    width: 55px;
    top: 64px;
    right: 92px;
    position: relative;
    height: 72px;
}

.p-1{
    cursor: pointer;
} 

.linksRight {
    position: relative;
    left: 117px;
    display: flex;
}

.rowTitle{
    color:$textColor;
    font-size: 23px;
    font-weight: 300;
    line-height: 60px;
    text-align: center;
}

.footerLinks{
    list-style-type: none;
    margin-right: -20px;
}

.footerLinks .Linkitem{
    width: auto;
    font-size:22px;
    color:#fff;
    font-family: $fontLight;
    cursor: pointer;
    line-height: 30px;
    transition: 300ms;
    text-align: left;

    &:hover{
       color:#ddd;
    }

    a{
        color:#fff;
    }
}

.download{
    display: flex;
    justify-content: center;
}

// .links{
    /*position: absolute;
    bottom: -360px;
    text-align: right;
    float: right;
    right: 150px;
    width: 652px;*/
// }


.footerItm {
    position: absolute!important;
    bottom: 51px;
    right: 0;
}



@media screen 
and (min-device-width : 1921px) 
and (max-device-width : 3000px) 
{ 
    .logoFooter {
        bottom: -352px;
    }

    .Footer {
        margin-top: 865px;
    }
    
}



@media screen 
and (max-device-width : 1680px) 
{ 

    .logoFooter {
        top: 96px;
    }

    
    .Footer {
        margin-top: 0;
    }

    .footerItm {
        bottom: 115px;
    }

   
}


@media screen 
and (max-device-width : 1500px) 
{ 

    .Footer {
        margin-top: -321px;
        height: 490px;
    }

    .footerLinks .Linkitem{
        font-size: 20px;
    }


    .logoFooter {
        top: 57px;
        width: 50px;
    }
}


@media screen 
and (max-device-width : 1440px) 
{ 
.Footer {
    margin-top: -287px;
}
}

@media screen 
and (max-device-width : 1280px) 
{ 
    .Footer {
        margin-top: -276px;
    }
    // .lgUsr  .Footer{
    //     margin-top: 300px !important;
    // }
}



@media screen 
and (max-device-width : 1024px) 
{ 


    .footerLinks .Linkitem {
        font-size: 17px;
        line-height: 29px;
    }

    .linksRight {
       
        left: 0;
    }


    .links {
        bottom: -218px;
        right: 52px;
        width: 304px;
    }

    .logoFooter {
 top: 11px;
    right: 41px;
    width: 49px;
    }

    .Footer {

        margin-top: -159px;
    }


}




@media screen 
and (max-device-width : 767px) 

{ 
    
    .lgUsr ._loading_overlay_wrapper{
        height:auto;
    }

    .footerLinks .Linkitem {
        font-size: 14px;
        line-height: 20px;
    }
    .logoFooter {
        top: 15px;
        left: 10px;
        width: 37px;
        height: 51px;
    }

    .links {
        top: 10px;
        right: -32px;
    }
    .Footer {
        height: 300px;
        margin-top: -94px;
    }
    

    .lgUsr .Footer {
        // bottom:50px;
        margin-top: 120px;

        
    }

    .lgUsr .linksRight {
        right: -22px;
    }


    .linksRight {
        left: -38px;
    }

    .footerItm {
        top: 160px;
    }
}



@media screen 
and (max-device-width : 375px) 
{ 

    

}

.mapContainerFooter {
  width: 100% !important;
  height: 400px;
  padding: 0;
  margin: 0;
  flex-basis: auto !important;
}

.contactInfo {
  margin-bottom: 50px;
  .contactInfoLabel {
    font-family: $fontBold;
    color: #093d77;
  }
  .contactInfoItem {
    margin-bottom: 10px !important;
  }
}
.h6-email{
    display: row !important;
    flex-direction: row !important;
}
.class1{
    margin-left: 40px !important;
}
.modalFooter {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.h6-email{
    width: 100% !important;
    display: row !important;
    flex-direction: row !important;
}
.class1{
    margin-left: 70px ;
}
