@import "../../../Assets/css/variable";
@import 'node_modules/react-modal-video/scss/modal-video.scss';


$arrowColor: #7129c6 !important;
$titleColor:#0b71d9 !important;
$textColor:#529D98!important;
.noMarginRow.row{
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.AwesomeSlider-costume{
    border: solid black !important;
    height: 700px;
    justify-content: center !important;
    padding-top:10px ;
}
.awssld__controls button {
 padding-top: 80px;
}
.modal-video-close-btn:before {
    -moz-box-shadow: 0.25px 0.25px 0.25px 0.25px #ccc;
    -webkit-box-shadow: 0.25px 0.25px 0.25px 0.25px #ccc;
    box-shadow: 0.25px 0.25px 0.25px 0.25px rgb(122, 122, 122);
  }
  
  .modal-video-close-btn:after {
    -moz-box-shadow: 0.25px 0.25px 0.25px 0.25px #ccc;
    -webkit-box-shadow: 0.25px 0.25px 0.25px 0.25px #ccc;
    box-shadow: 0.25px 0.25px 0.25px 0.25px rgb(122, 122, 122);
  }

.videoSlider{
 
    background-position: bottom;
    background-size: 100%;
}

.parentCol{
    margin-left: 0 !important;
}
.modal-video-body {
    max-width: 69vw;
}


.videoSlider .awssld__content{
    background: transparent;
}
.videoSliderLogged .awssld__content{
    background: transparent;
}


.videoSlider .awssld__next,.videoSlider .awssld__prev{
    &:focus{
        outline: none;
    }


}
.videoSliderLogged .awssld__next,.videoSliderLogged .awssld__prev{
    &:focus{
        outline: none;
    }


}

.videoSlider .awssld__controls__arrow-right, .videoSlider .awssld__controls__arrow-left{
    &::before{
        background: $arrowColor;
    }

    &::after{
        background: $arrowColor;
    }
}
.videoSliderLogged .awssld__controls__arrow-right, .videoSliderLogged .awssld__controls__arrow-left{
    &::before{
        background: $arrowColor;
    }

    &::after{
        background: $arrowColor;
    }
}

.videoSlider .awssld__controls{
    display: block!important;

}
.videoSliderLogged .awssld__controls{
    display: block!important;

}

.videoSlider .awssld__prev{
    top: -135px !important;
}
.videoSliderLogged .awssld__prev{
    top: -22px !important;
}


.awssld__controls button .awssld__controls__arrow-left, .awssld__controls button .awssld__controls__arrow-right{
    opacity:0.9!important;
}
.videoSliderLogged .awssld__next {
    top: 409px !important;
}

.videoSliderLogged .awssld__bullets button{
    border-radius: 50%;
    background: $arrowColor;
    transition: 0.1s;
}

.videoSlider  .awssld__wrapper {
    width: 100%;
    margin: 0 auto;
}

.videoSliderLogged .awssld__container{
    width: 98%;
}









.videoSlider .awssld__next {
    top: 409px !important;
}

.videoSlider .awssld__bullets button{
    border-radius: 50%;
    background: $arrowColor;
    transition: 0.1s;
}

.videoSlider  .awssld__wrapper {
    width: 100%;
    margin: 0 auto;
}

.videoSlider .awssld__container{
    width: 100%;
}

  .vidImg{
    width: 100%;
    height: auto;

  }



.roundBg{
    background: transparent;
    width: 100%;
    margin: 0 auto;
    border-radius: 50px;
    height: 640px;
    position: absolute;
    left: 0;
    right: 0;
    top: -100px;
}
.roundBgLogged{
    background: transparent;
    width: 100%;
    margin: 0 auto;
    border-radius: 50px;
    height: 640px;
    position: absolute;
    left: 20px;
    right: 0;
    top: 0;
}

.videoSlider .awssld__bullets{
    display: none;
}
.videoSliderLogged .awssld__bullets{
    display: none;
}

.HowitWorkTitle{
    color: $titleColor;
    font-weight: 400;
    font-size: 40px;
    text-align: center;
    padding: 80px 0 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.videoSliderLogged{
    position: relative;
    height: 100vh;

}
.videoSlider{
    position: relative;
    height: 100vh;

}

.sliderTitle{
    font-family: $fontLight;
    font-size: 55px;
    color:#000;
  letter-spacing: -2px;
  margin-top: 80px;
  line-height: 48px;
  text-align: right;
} 

.sliderTitleBold{
    font-family: $fontBold;
}

.secTitle{

    margin-top: 5px;
}

.sliderDesc{
    // font-size: 30px;
    // margin-top: 38px;
    // color: #000 !important;
    // line-height: 34px;
    // text-align: right;
    




    position: absolute;
    font-size: 30px;
    margin-top: 38px;
    color: #000 !important;
    line-height: 34px;
    text-align: right;
    width: 150%;
    right: 50px;
}

  .playBtnVid{
      background: url('../../../Assets/img/playVideBtn.png') no-repeat;
      width: 329px;
      height: 327px;
      position: absolute;
      cursor: pointer;
      transition: 0.3s;
      transform: scale(0.9);

      &:hover{
          transform: scale(1);
      }
  } 

  .rightVideoImage{
    display: flex;
    justify-content: center;
    align-items: center;

  }


.content {
    position: absolute!important;
    left:0;
    right:0;
    top:0;
    bottom:0;
    width: 100%;
    margin:0 auto;
    padding-top: 0;

}

.titleIcon{
    margin-right: 10px;
    position: relative;

}




@media screen 
and (min-device-width : 1921px) 
and (max-device-width : 3000px) 
{
    .videoSlider .awssld__prev {
        top: -57px !important;
    }

    .videoSlider {
        height: 113vh;
    }
    .videoSliderLogged .awssld__prev {
        top: -57px !important;
    }

    .videoSliderLogged {
        height: 113vh;
    }

}


@media screen 
and (max-device-width : 1920px) 
{
    .sliderTitle {
        padding-top: 20px;
    }

    .HowitWorkTitle {
        padding: 80px 0 100px;
    }

    .videoSlider {
        height: 103vh;
        top: 30px;
    }
    .videoSliderLogged {
        height: 1088px;
        top: 30px;
    }

}

@media screen and (max-device-width : 1600px) { 


    .videoSlider {
        height: 90vh;
        top:-45px;
    }


    .videoSlider .awssld__prev {
        top: -81px !important;
    }
.videoSliderLogged {
        height: 869px;
    }
}

@media screen 
and (max-device-width : 1440px) 
{ 
     

    .videoSlider {
        height: 90vh;
    }

        .videoSlider .awssld__prev {
            top: -66px !important;
        }

 .videoSliderLogged {
            height: 90vh;
            top:0;
        }

        .videoSliderLogged .awssld__prev {
            top: -29px !important;
        }
        .roundBg {
            height: 513px;
            width:100%;
        }
      
        .playBtnVid{
            zoom:0.7;
        }
        

        .sliderDesc {
            font-size: 22px;
            line-height: 27px;
        }

        .sliderTitle {
            font-size: 45px;
        }

        .videoSlider .awssld__bullets {
            bottom: 188px !important;
        }
        .videoSliderLogged .awssld__bullets {
            bottom: 188px !important;
        }

        .container {
            width: 1400px !important;
        }

        .videoSlider .awssld__next {
            top: 300px !important;
        }
        .videoSliderLogged .awssld__next {
            top: 359px !important;
        }
}
@media screen 
and (max-device-width : 1280px) 
{ 

.videoSlider .awssld__prev {
    top: -100px !important;
}

}
@media screen 
and (max-device-width : 1440px) 
{ 

.videoSlider .awssld__prev {
    top: -110px !important;
}

}
@media screen 
and (max-device-width : 1024px) 
{ 

    .videoSlider {
        top: 121px;
    }

    .container {
        max-width: 100%!important;
        width:100%!important;
    }



    .playBtnVid{
        zoom: 0.3;
    }

    .sliderTitle {
        font-size: 36px;
        line-height: 36px;
        margin-left: 18px;
        margin-top: 28px;
    }




    .videoSlider{
        background-position: top;
        height: 55vh;
    }
     .videoSliderLogged{
        background-position: top;
        height: 650px;
        top: -70px;
    }
    .rightVideoImage{
        margin-top:30px;
    }



    .sliderDesc {
        font-size: 18px;
        line-height: 23px;
        margin-top: 10px;
        margin-left: 19px;
    }

 
    .videoSlider .awssld__next {
        top: 412px !important;
    }

 .videoSlider .awssld__prev {
        top: 10px !important;
    }
    .videoSlider .awssld__next,     .videoSlider .awssld__prev{
        zoom:0.6;
    }
.videoSliderLogged .awssld__next {
        top: 290px !important;
    }
    .videoSliderLogged .awssld__prev {
        top: 10px !important;
    }
    .videoSlider .awssld__prev {
        top: -52px !important;
    }

    .sliderTitle {
        margin-top: 47px;
    }

    .videoSlider {
        height: 70vh;
    }

    
    .aboutUsContent .item:first-child, .aboutUsContent .item:nth-child(2), .aboutUsContent .item:last-child {
        background-size: contain;
    }

  

}



@media screen 
and (max-device-width : 1024px) and (orientation : landscape){


    .videoSlider{
        top: 70px;
    }

    .videoSlider .awssld__next {
        top: 343px !important;
    }
 .videoSliderLogged{
        top: 70px;
    }

    .videoSliderLogged .awssld__next {
        top: 242px !important;
    }

    .sliderTitle {
        margin-top: 23px;
    }




}


@media screen 
and (max-device-width : 768px) {



}


@media screen 
and (max-device-width : 767px) {
    .videoSliderLogged .awssld__wrapper {
        height: 330px !important;
    }
    .videoSliderLogged .awssld__box{
    
        height: 330px !important;
    }
    .videoSlider .awssld__bullets{
        display: inline-flex !important;
        bottom: -40px !important;
        
     }
     .videoSliderLogged .awssld__bullets{
        display: inline-flex !important;
        bottom: -40px !important;
        
     }
     .videoSlider .awssld__bullets button{
         width: 10px;
         height: 10px;
     }
     .videoSliderLogged .awssld__bullets button{
        width: 10px;
        height: 10px;
    }
  
    .sliderTitle {
        font-size: 20px;
        line-height: 27px;
    }

    // .sliderDesc {
    //     font-size: 15px;
    //     line-height: 17px;
    //     margin-top: 7px;
    // }

    .sliderDesc {
        right: 40px !important;
        width: 85% !important;
        font-size: 14px;
        line-height: 15px;
        margin-top: 5px;
    }



    .sliderTitle {
        margin-top: -55px;
        padding-top: 0;
    }

    .videoSlider {
        height: 55vh;
        top: 31px;
    }

.videoSliderLogged {
        height: 200px;
        top: 10px;
    }
  

    .playBtnVid {
        zoom: 0.2;
    }

    .content{
        padding-top: 0!important;
    }

    .rightVideoImage {
        margin-top: 10px;
    }

    .modal-video-body {
        max-width: 80vw!important;
    }

    .content{
        width:100%;
    }

}


@media screen 
and (max-device-width : 812px) and (orientation : landscape){
    .videoSlider .awssld__bullets{
        display: inline-flex !important;
     }
.videoSliderLogged {
        height: 136vh;
    }
}

@media screen 
and (max-device-width : 737px) and (orientation : landscape){
    .videoSlider .awssld__bullets{
        display: inline-flex !important;
     }
.videoSliderLogged {
        height: 88vh;
    }
}



@media screen 
and (max-device-width : 375px) {
    .videoSlider .awssld__bullets{
        display: inline-flex !important;
     }
    .vidImg{
        width: 100%;
        height: 165px !important;
    
      }
.sliderTitle {
    padding-top: 20px ;
        font-size: 20px;
        line-height: 20px;
    }
    .sliderDesc {
        right: 40px !important;
        width: 85% !important;
        font-size: 14px;
        line-height: 15px;
        margin-top: 5px;
    }
}


