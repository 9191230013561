$backgroudMessageCat1: #4e73df;
$backgroudMessageCat2: #1cc88a;
$backgroudMessageCat3: #f6c23e;
.messageImg {
  background-color: $backgroudMessageCat1;
  border-radius: 50%;
  width: 37px !important;
}

.message {
  text-overflow: ellipsis;
  font-weight: 700 !important;
}

.messageDetails {
  font-size: 80%;
  font-weight: 400;
  color: #b7b9cc !important;
}

.massageCategory1 {
  color: white;
  background-color: $backgroudMessageCat1;
}

.massageCategory2 {
  color: white;
  background-color: $backgroudMessageCat2;
}
.massageCategory3 {
  color: white;
  background-color: $backgroudMessageCat3;
}

.popover-body {
  padding: 0 !important;
  margin: 0;
}
