@import "../../../Assets/css/variable";


$titleColor :#09b4b8;
$descColor:#0a91c9;
$iconColor:#7b4b94;
$bigTitleColor:#0b71d9;



.AboutUs{

  position: relative;
  height: 0;
  top: -215px;
  width: 100%;
  z-index: 0;
}
.AboutUsNavBarNormal{
  // position: relative;
  // height: 0;
  top: -215px;
  width: 100%;
  z-index: 0;
}
.aboutUsContent{
  width: 70%;
  margin: 0 auto;
}


.aboutUsContent .item{
  height: 420px;
  padding: 70px;
}
.classNavBarContent .item {
  height: 420px;
  padding: 70px;
}
.aboutUsContent .item:first-child {
  background: url('../../../Assets/img/Text1.png') no-repeat;
  background-size: 100%;
}
.classNavBarContent .item:first-child {  
  background: url('../../../Assets/img/Text1.png') no-repeat;
  background-size: 100%;
  // height: 300px;
  // width: 300px !important;
}
.aboutUsContent .item:nth-child(2) {
  position: relative;
  top:150px;
  background: url('../../../Assets/img/Text2.png') no-repeat;
  background-size: 100%;
}
.classNavBarContent .item:nth-child(2){  
  background: url('../../../Assets/img/Text2.png') no-repeat;
  background-size: 100%;
  top:150px;
  // height: 300px;
  // width: 300px !important;
}
.aboutUsContent .item:last-child {
  background: url('../../../Assets/img/Text3.png') no-repeat;
  background-size: 100%;
}

.classNavBarContent .item:last-child{  
  background: url('../../../Assets/img/Text3.png') no-repeat;
  background-size: 100%;
  // height: 300px;
  // width: 300px !important;
}


.AboutUsTitle{
    font-family: $fontLight;
    font-size: 50px;
    text-align: center;
    width: 100%;
    position: relative;
    top: 120px;
    color:#000;

    b{
      font-family: $fontBold;
    }
}




.AboutUs .titleIcon {
  top: -6px;
}
.AboutUsNavBarNormal .titleIcon{
  top: -6px;
}
.itmTitle{
  color:#fff;
  font-size: 26px;
  font-family: $fontBold;
  margin-top:30px;
}

.itmTitle::before{
  content:url('../../../Assets/img/aboutIcon.png');
  width:38px;
  height:38px;
  margin-right:15px;
  zoom:0.7;
}

.stepsTexts{
  text-align: left;
}

.itmDesc {
  color: #fff;
  font-size: 17px;
  padding-top: 9px;
  line-height: 20px;
}
 

.priceIcon{
  font-size:60px;
  color:$iconColor;
  background:#00908e;
  border-radius:50%;
  width: 120px;
  height:120px;
  display: flex;
  justify-content: center;
  align-items: center;

margin: 0 auto;
    box-shadow: 0 1px 3px 0 rgba(89, 98, 115, 0.12);
    -webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
    transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
    transition: transform 200ms ease, box-shadow 200ms ease;
    transition: transform 200ms ease, box-shadow 200ms ease, -webkit-transform 200ms ease;
cursor: pointer;

  &:hover{
    box-shadow: 0 6px 12px 0 rgba(89, 98, 115, 0.12);
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02);

  }
}



@media screen 
and (min-device-width : 1921px) 
and (max-device-width : 3000px) 
{ 
  .AboutUs{
    height: 2550px;
    top: -250px;
  }
.AboutUsNavBarNormal{
  height: 2550px;
  top: -250px;
}
}

@media screen 
and (max-device-width : 1680px) 
{ 

  .AboutUs {
    top: -352px;
  }
.AboutUsNavBarNormal{
  top: -352px;
  
}
  .AboutUsTitle {
    padding: 70px 0 50px;
  }


    .itmTitle {
      font-size: 26px;
    }

    .aboutUsContent .item {
      height: 433px;
      padding: 46px;
  }


}


@media screen 
and (max-device-width : 1440px) 
{ 

  .AboutUs{
    top: -478px;
  }
   .AboutUsNavBarNormal{
    top: -478px;
     
   }

  .aboutUsContent .item {
    padding: 70px;
  }

  .AboutUsTitle {
    padding: 50px 0 49px;
    font-size: 57px;
}

.itmTitle {
  font-size: 30px!important;
}

.itmDesc {
  font-size: 19px;
}



.aboutUsContent {
  width: 90%;
}

}




@media screen 
and (max-device-width : 1440px) {

.aboutUsContent{
  margin-top: 250px;
}

}

@media screen 
and (max-device-width : 1280px) {
  .AboutUs {
    top: -500px;
    
  }

  .aboutUsContent .item {
    padding: 53px;
}
.aboutUsContent{
  margin-top: 300px;
}

}


@media screen 
and (max-device-width : 1024px) 
{ 

  .AboutUsTitle {
    font-size: 40px;
    padding:0;

  }

  .AboutUs {
    top: -429px;
}
.AboutUsNavBarNormal{
  top: -429px;
  
}
.itmTitle::before {
  position: relative;
  top: 9px;
}
  .itmTitle {
    font-size: 30px!important;
  }

  .itmDesc {
    font-size: 14px;
    line-height: 16px;
  }

}



@media screen 
and (max-device-width : 1024px) and (orientation : landscape){

  .itmTitle {
    font-size: 22px !important;
  }

  .itmDesc {
    font-size: 16px;
}

.aboutUsContent .item {
    padding: 38px;
}

}


@media screen 
and (max-device-width : 768px) 
{ 
    .itmTitle {
      font-size: 20px !important;
    }

    .AboutUsTitle {
      top: 9px;
    }



  .itmDesc {
    font-size: 17px;
    line-height: 19px;
}

.aboutUsContent .item {
  height: 258px;
}

}

@media screen 
and (max-device-width : 767px) 
{ 
  .classNavBarContent{
    width: 100%;
    margin: 0 auto;
  }
  .classNavBarContent .item {
    height: 220px;
    padding: 0;
  }
  .classNavBarContent .item:nth-child(2) {
    top: 4px;
  }
  .AboutUsTitle {
    font-size: 25px;
  }
  .aboutUsContent{
    margin-top: 50px;
  }
  

  .itmDesc {
    font-size: 15px;
  }

  .itmTitle {
    font-size: 22px !important;
  }

  .itmTitle::before {
    zoom: .5;
    top: 2px;
  }

  .aboutUsContent .item:nth-child(2) {
    top: 4px;
  }

  .AboutUsTitle {
    padding: 100px 0 32px;
  }

  .aboutUsContent .item {
    height: 272px;
}

  .AboutUs {
    height: 917px;
    z-index: -1;
  }
.AboutUsNavBarNormal{
  height: 917px;
  z-index: -1;
}
}

@media screen 
and (max-device-width : 375px) 
{ 
  .aboutUsContent{
    margin-top: 50px;
  }
.aboutUsContent .item {
  padding: 39px;
}

}