.termsIntro{
  background-color: red !important;


}
.TitleText{
background-color: red !important;
text-align: center;
}

.terms{
    height: 100%;
    overflow-x: hidden;
    max-height: 80vh;
    overflow-y: scroll;
}
.fullCenteredTerms{
  width:100%;
  text-align: left !important;
}
  .terms::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #76f5bc;
  }
  
  .terms::-webkit-scrollbar {
    border-radius: 5px;
    width: 5px;
    background-color: #fff;
  }
  
  .terms::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #093d77;
  }

  .leftalignFollow{
    text-align: left;
    margin:  0 -10px 0 0 !important;
}

.moreleftalign{
  text-align: left;
  margin-left: 50px;
}
.tr-big td:last-child{
  width: 300px;
  padding: 20px !important;
  .td{
      height: 400px !important;
      width: 100px !important;
      padding: 20px !important;
  }
}
.tr-big td{
  width: 150px;
  padding: 20px !important;
}
