@import "../../../Assets/css/variable";

@mixin box-shadow($params) {
  -webkit-box-shadow: $params;
  -moz-box-shadow: $params;
  box-shadow: $params;
}

$shadows: 0px 0px 5px rgba(0, 0, 0, 0.25), 0px 0px 0px #000 inset;
//@include box-shadow($shadows);

$hamb: #fff;
$hambHover: #ffff;
.sponsorImage{
  width: 100% !important;
  justify-content: center !important;
}
.butonNavTop {
  display: flex;
  justify-content: flex-start;
}

.navbar-div {
  .nav-icons {
    margin-left: 5px;
    margin-right: 5px;
    width: 32px !important;
    cursor: pointer !important;
  }
}
.button {
  float: right;
}

.logoParent{
  display: flex;
    justify-content: flex-end;
}

.sliderText{
  margin-left: 60px;
  margin-top: 72px;
}


.logo {
  width: 135px;
  margin-left: 20px;
}
.sponsor {
  width: 160px;
  margin-left: 50%;
}

.navbar-toggler::after {
  display: none !important;
}

.navbar-custom .navbar-toggler {
  border: none;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: transparent;
    transform: scale(1.06);
  }

  &:focus {
    background: transparent;
    border: none !important;
    box-shadow: none;
    outline: none;
  }

  &:active {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
}
.dropdownmenuCustom {
  top: 23px !important;
  left: -20px !important;
  min-width: 250px !important;
  border: none !important;
  -webkit-box-shadow: -4px 7px 5px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -4px 7px 5px 1px rgba(0, 0, 0, 0.1);
  box-shadow: -4px 7px 5px 1px rgba(0, 0, 0, 0.1);
  transition: 0.3s !important;
}

.dropdown {
  outline: none !important;
}

.itemCustom {
  color: #1bb0b0 !important;
  transition: 0.2s;
  padding: 10px;
  font-size: 15px;

  &:hover {
    width: 95%;
    color: #083d77 !important;
    background: #1bb0b0 !important;
    margin: auto;
    border-radius: 3px;
  }
}

.Navbar {
  background: transparent;
  transition: 0.6s;
  // justify-content: space-between !important;
  height: 20px;
  position: absolute !important;
  z-index: 2;
  margin: 0;
  padding: 0;
  right: 0;
  top: 20px;
}

.nlgUsr .Navbar{
  background: transparent!important;
}

  .logoNavbar {
    height: 55px !important;
    position: relative;
    top: 0;
    left: -40px;
}
.logoNavbar-sponsor{
  height: 30px !important;
  position: relative;
justify-content: center;
  top: 10px;
  // left: 10px;
}

.signUpBtn2 {
  // margin-right: 100px !important;
  border-radius: 5px !important;
  position: relative;
  left: 70%;
  background: #fac227 !important;
  font-size: 17px !important;
  padding: 0.5em 1em !important;
  border: none !important;
  transition: 0.3s;
  color:#492ec4!important;
  font-family: $fontNormal;
  -webkit-box-shadow: 0px 9px 45.9px 8.1px rgba(29, 33, 33, 0.35);
  -moz-box-shadow: 0px 9px 45.9px 8.1px rgba(29, 33, 33, 0.35);
  box-shadow: 0px 9px 45.9px 8.1px rgba(29, 33, 33, 0.35);

  
  

  &:hover {
    background: #fac227  !important;
  }
}

.rightSide {
  right: 10%;
}

.err-text {
  text-align: center !important;
  margin-top: 30px;
}
.err-btn {
  justify-content: center;
}
.appBar {
  position: relative;
}
.saveClose-Parent {
  align-self: flex-end;
  margin-top: 1rem !important;
  margin-right: 1rem !important;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: 0 !important;
  top: 15px;
  z-index: 5;
  margin-left: -1.5rem !important;
  background-color: transparent !important;
  color: rgb(194, 194, 194) !important;
  cursor: pointer;
  text-align: center;
  font-weight: 700;
  font-size: 25px;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 33px;
  height: 30px;
  top: 4px;
  // left: 90%;
  right: 8%;

}
.nlgUsr{
  .bm-burger-button {
    position: absolute;
    width: 33px;
    height: 30px;
    top: 4px;
    left: 10% !important;
    // right: 8%;
  
  }
}


.bm-burger-button span:nth-child(2) {
  width: 40px !important;
}

button:focus {
  outline: none !important;
}
.bm-burger-bars {
  background: #093d77 !important;
  transition: 0.6s;
  height: 7px !important;
}
/* Color/shape of burger icon bars */

.bm-burger-bars-loged {
  background: $hamb;
  transition: 0.6s;
  height: 7px !important;
}

.BtnClass{
  .bm-burger-bars {
    background: #ffff !important;
    transition: 0.6s;
    height: 7px !important;
  }
}
.BtnClassFalse{
  .bm-burger-bars {
    background: #093d77 !important;
    transition: 0.6s;
    height: 7px !important;
  }
}
/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: $hamb;
  opacity: 0.8 !important;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #a3a6a8;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 150px;
  
  height: 400px !important;
  width: 210px !important;
}
.BeforeLogIn {
  left: 0 !important;
}
.bm-menu {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: #5447d5d9 !important;

  // box-sizing: border-box;
  // overflow: scroll !important;
  // height: 450px !important;
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  padding: 3.5em 1.5em 0;
  // font-size: 1.15em;
  -webkit-box-shadow: -2px -2px 23px -8px rgba(0,0,0,0.42);
-moz-box-shadow: -2px -2px 23px -8px rgba(0,0,0,0.42);
box-shadow: -2px -2px 23px -8px rgba(0,0,0,0.42);

}

.bm-item.menu-item {
  color: #fff;
  margin-bottom: 16px;
  transition: 0.1s;
  font-size: 17px;
  height: auto;
}

.bm-cross {
  background: #fff;
  height: 30px !important;
  border-radius: 5px;
}

.bm-item.menu-item:focus {
  outline: none !important;
}

.bm-item.menu-item:hover {
  color: $hambHover;
  transform: scale(1.04);
}

.bm-item.menu-item:hover {
  text-decoration: none;
  color: #fac227 ;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0;
}

.bm-item-list a {
  cursor: pointer;
}

.bm-burger-bars {
  border-radius: 10px;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: transparent !important;
}

@media screen and (min-device-width: 1921px) {
  .signUpBtn2 {
    font-size: 1.2vw !important;
  }
}

@media screen and (max-device-width: 1500px) {


  .bm-menu-wrap {
    width: 210px !important;
  }
}
@media screen and (max-device-width: 1440px) {
  .bm-menu {
    // border-top-left-radius: 10px;
    // border-bottom-left-radius: 10px;
    // background: #5447d5d9 !important;
  
    box-sizing: border-box;
    
    overflow: auto !important;
    height: 450px !important;
  }
}
@media screen and (max-device-width: 1280px) {
  .bm-menu-wrap {
    width: 195px !important;
  }
  .bm-menu {
    // border-top-left-radius: 10px;
    // border-bottom-left-radius: 10px;
    // background: #5447d5d9 !important;
  
    box-sizing: border-box;
    overflow: auto !important;
    height: 450px !important;
  }
  .Navbar{
    top: 5px;
  }

  .bm-item.menu-item {
    font-size: 16px;
  }

  .btext, .ltext {
    font-size: 26px!important;
    line-height: 26px!important;
  }

  .signUpBtn2 {
    font-size:15px!important;
  }
}

@media screen and (max-device-width: 1024px) {
  .bm-burger-button {
    top: 6px;
  }

  .logoNavbar {
    top: 3px;
  }

  .signUpBtn2 {
    font-size: 13px !important;
    /* padding: 13px 54px !important; */
    position: relative;
    margin-top: 6px !important;
  }

  .butonNavTop {
    display: flex;
    align-items: flex-start;
    height: auto;
    width: 400px;
    justify-content: flex-start;
  }
}

@media screen and (max-device-width: 1024px) and (orientation: landscape) {
  .signUpBtn2 {
    font-size: 1.5vw !important;
  }


}

@media screen and (max-width: 767px) {

  .Navbar {
    top: 20px;
}


  .nlgUsr .navbar{
    height: 84px;
    position: absolute!important;
    top: 10px;
  }

  
  // .signUpBtn2 {
  //   font-size: 12px!important;
  //   margin-right: 0 !important;
  //   right: 0;
  //   padding: 0.5em 0.5em !important;
  // }

  .butonNavTop {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .logoParent{
    padding:0!important;
    margin-top: -36px;
    
  }

  .sponsorImage img{
    width:150px;
  }

  .sliderText {
    font-size: 18px;
    line-height: 16px;
    margin-top: 78px !important;
}



  
  .logoNavbar {
    top: 19px;
    left: -2px;
    height: 30px !important;
  }

  .bm-burger-bars {
    height: 5px !important;
  }

  .sponsorImage {
    right: 0;
    position: absolute!important;
    z-index: 9;
    left: 0;
    margin: 0 auto;
    top: 0;
  }

  .bm-burger-button {
    height: 22px;
    width: 30px;
    top: 13px !important;
    right: -43px;
  }

  .bm-burger-button span:nth-child(2) {
    width: 36px !important;
  }



  .bm-item.menu-item {
    font-size: 15px !important;
  }

  .bm-cross {
    height: 21px !important;
  }

  .bm-menu-wrap {
    width: 150px !important;
    top: 70px;
    height: auto !important;
  }
}

@media screen and (max-device-width: 736px) and (orientation: landscape) {
  .signUpBtn2 {
    font-size: 2vw !important;
  }
}

@media screen and (max-device-width: 812px) and (orientation: landscape) {
  .signUpBtn2 {
    font-size: 2vw !important;
  }
}



@media screen and (max-width: 375px) {
  .signUpBtn2  {
    width: 125px;
    left: 99px !important;
    top: 7px;
    
  }

}

@media screen and (max-width: 414px) {

  .signUpBtn2  {
    width: 125px;
    left: 120px;
    top: 7px;
    
  }
  
  }
@media screen and (max-width: 320px) {
  .signUpBtn2  {
    width: 125px;
    left: 99px !important;
    top: 7px;
    
  }

  .bm-burger-button {
    right: -22px;
}


}