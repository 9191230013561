.placeNameLabel{
    font-family: "CircularAirBold";
    color: #2d5683;
}
.placeName{
    background-color: #fff !important;
    border-radius: 10px;
    color: #2d5683;
    border: 1px solid #959595;
    cursor: pointer;
    font-family: "CircularAirBook";
    padding: 10px;
    height: 32px;
    width: 100%;
}
.placeNameCosts{
    background-color: #fff !important;
    color: #2d5683;
    border: 1px solid #959595;
    border-right: none;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    cursor: pointer;
    font-family: "CircularAirBook";
    padding: 10px;
    height: 32px;
    width: 100%;

}
.euroSignParent{
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid  #959595 ;
    border-left: none;
    height: 32px;
width: 15%;
padding-top: 2.5px;
}
.euroSign{
    color: #2d5683;
   
    // width: 30px !important;
}
.locationButtons{
    background-color: #2d5683 !important;
    color: #ffffff !important;
    margin: 9px;
    min-width: 100px;
    height: 37px;
}
.locSaveCnt .row{
    margin:5px 0px!important;
}
.ESignLocatio{
    display: flex !important;
    justify-content: center !important;
    align-items:  center !important;
}
@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.errortxt{
    color: red ;
    // border: solid black ;
    
    width: 100%;
    text-align: center;
    height: 17px;
    font-size: 10px;
    opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1s;
}
