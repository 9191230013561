.voucherLogo {
  height: 100px !important;
  width: 100px !important;
  border: solid black;
  border-width: 2px;
  background-color: white !important;
}

.voucherBG {
  .form-control {
    border-left: none !important;
    border-top: none !important;
    border-bottom: none !important;
    border-right: 1px solid #ced4da;
  }

  .MuiAutocomplete-popupIndicator {
    position: relative;
    top: -6px;
    border-left: 1px solid #fff;
    border-right: none;
    border-radius: 0;
  }

.MuiAutocomplete-popupIndicatorOpen {
  border-right: 1px solid #fff;
  border-left: none;
}

.MuiAutocomplete-popupIndicator svg {
  font-size: 35px !important;
}
.iconTooltipVoucher{
  /* // border: solid black !important; */
  margin-left: 4px;
}
.selectEventPic-parinte{
  // border: solid black !important;
 width: 100% !important;
  .selectEventPic {
    border-radius: 5px !important;
    // max-height: 100px;
    height: 100px !important;
    object-fit: cover;
    // width: 100%;
    justify-content: center !important;
    width: 20% !important;
    margin: 0  0  20px 30px  !important;
  }
}


@media only screen and (max-width: 375px) {
  .selectEventPic-parinte{
    // border: solid black !important;
    
  .selectEventPic {
    margin: 0  0  20px 30px  !important;
    border-radius: 5px !important;
    height: 100px !important;
    object-fit: cover;
    width: 50% !important;
    
  }
}
}

  background: white;
  margin: 0 auto;
  width: 100%;
  padding: 37px;

  input {
    background: #694b99 !important;
    color: #fff !important;
    border-radius: 15px;
    height: 50px;
    padding-left: 15px;
  }

  label {
    color: #2d5683 !important;
  }

  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #fff !important;
  }
  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #fff !important;
  }
  input:-ms-input-placeholder {
    /* IE 10+ */
    color: #fff !important;
  }
  input:-moz-placeholder {
    /* Firefox 18- */
    color: #fff !important;
  }

  .btnDone {
    background-color: #fac227 !important;
    color: #492ec4;
    border-radius: 50px;
    font-family: "CircularAirBold";
    font-size: 15px !important;
    cursor: pointer;
    transition: 0.3s;
    border: none !important;
    text-transform: capitalize !important;
    border-radius: 50px !important;
    padding: 8px 35px !important;
    transition: 0.3s;

    &:hover {
      color: #492ec4;
      background: #daa001 !important;
    }
  }
  
  
  
  
  .btnDoneDel {
    background-color: #db0f0f !important;
    color: #ececec;
    border-radius: 50px;
    font-family: "CircularAirBold";
    font-size: 15px !important;
    cursor: pointer;
    transition: 0.3s;
    border: none !important;
    text-transform: capitalize !important;
    border-radius: 50px !important;
    padding: 8px 35px !important;
    transition: 0.3s;

    &:hover {
      color: #ffffff;
      background: #a30c0c !important;
    }
  }
  
  
  
  
  .datePicker {
    z-index: 2 !important;
    position: relative !important;
  }
  .categoryInputV #combo-box-demo4 {
    border: none;
    border-radius: 15px;
    height: 20px;
    padding-left: 10px;
    
  }
  .categoryInputB {
    border-radius: 15px;
    background-color: #694b99 !important;
    width: 100%;
    display: flex;
    align-items: center;
    height: 50px;
  }

  //remove arrows from number input
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .invisible {
    display: none;
  }

  .form-control.is-valid {
    border-color: white;
  }

  .form-control.is-invalid {
    border-color: white;
  }
  .form-control.is-invalid:focus {
    border-color: white;
  }
}


@media screen and (max-device-width : 767px)
{

  .voucherBG .MuiAutocomplete-popupIndicator {
    top: 7px;
  }
}
.voucherLoc .input-group-append {
  top: 40px!important;
}

.btnDonePopover {
  background-color: #20b55c !important;
  color: white;
  border-radius: 50px;
  font-family: "CircularAirBold";
  font-size: 15px !important;
  cursor: pointer;
  transition: 0.3s;
  border: none !important;
  text-transform: capitalize !important;
  border-radius: 50px !important;
  transition: 0.3s;
  margin-bottom: 10px;
  margin-top: 5px;

  &:hover {
    background: #156b37 !important;
  }
}

.backgrounButtonVoucher {
  background-color: #694b99 !important;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 50px;
}
.RonSign{
  // border: solid black;


  justify-content: center !important;
}

.swalElement{
  display: flex;
  text-align: center;
  justify-content: center;
}
.locations_promotions {
  width: 100%;
  height: 50px;
  border: none;
  background-color: #694b99 !important;
  border-radius: 8px;
  margin-top: 10px;
  padding-right: 10px;

}

.autocomplete-dropdown-promotions div {

  padding: 7px 5px 7px 15px;
  font-size: 14px;
}
