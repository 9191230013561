.imageBox {
  width: 100px !important;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  background-color: white;
}

.datePicker {
  z-index: 100 !important;
}

.imageBox2 {
  border-style: solid;
  border-color: black;
  border-width: 2px;
  background-color: white;
}

.input-group {
  margin-bottom: 20px;
}

.no-of-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit;
}

// .inputDrop{
//   width: 160px;
//   display: block;
//   font-weight: 400;
//   line-height: 1.5;
//   border-right-width: 1.5px;
//   border-style: solid;
//   border-right-color: white;
//   margin-bottom: 5px;
//   border-top-left-radius: 10px;
//   border-bottom-left-radius: 10px;
//   color: #fff !important;
//   background: #2d5683 !important;
//   font-size: 15px !important;
//   padding-left: 10px !important;
//   height: 50px;
//   border-left: none;
//   border-top: none;
//   border-bottom: none;
// }
.inputDrop {
  width: 30px !important;
  display: block;
  font-weight: 400;
  font-size: 13px !important;
  // line-height: 1.5;
  border-right-width: 1.5px;
  // border-style: solid;
  border-right-color: white;
  // margin-bottom: 5px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #fff !important;
  background: #694b99 !important;
  // font-size: 15px !important;
  // padding-left: 10px !important;
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  height: 50px;
  border-left: none;
  border-top: none;
  border-bottom: none;
  .inputDrop:hover{
    background-color: #798fa7 !important;
  }
}
.backgrounButton {
  background-color: #694b99 !important;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 50px;
}
.backgrounButton-price-sign{
  background-color: #694b99 !important;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 50px;
  width: 50px !important;
  .price-sign{
    width: 35px !important;
    .p-ron{
      font-weight: 400 !important;
      font-size: 13px !important;
      justify-content: center !important;
      text-align: center !important;
      margin:  12px 20px 0 0 !important;
      min-height: 100% !important;
    }
  }
}

.iconTooltipPrice{
  margin-left: 5px !important;
}
.droppDownMakeEventB {
  color: #2d5683;
  font-size: 17px;


}

.dropdownMenuBusiness button:hover {
  .droppDownMakeEventB {
    color: #fff !important;
  }

}

.dropdownMenuBusiness {
  height: 200px;
  overflow: scroll;
}

.makeEventsbusiness {
  .invisible {
    display: none;
  }

  .form-control.is-valid {
    border-color: white;
  }

  .form-control.is-invalid {
    border-color: white;
  }

  .form-control.is-invalid:focus {
    border-color: white;
  }

  input {
    margin-bottom: 5px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: #fff !important;
    background: #694b99 !important;
    height: 50px;
    font-size: 15px !important;
    padding-left: 10px !important;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 50px;

    border-left: none;
    border-top: none;
    border-bottom: none;
  }

  ::placeholder {
    color: seashell !important;
  }

  label {
    color: #2d5683 !important;
  }

  //remove arrows from number input
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

textarea {
  margin-bottom: 10px;
  border-radius: 10px;
  color: #fff !important;
  background: #2d5683 !important;
  height: 1000px;
}

.genderIcon {
  width: 50px;
  height: 50px;
  background: #694b99 !important;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:active {
    background: #fac227 !important;
  }

  &:focus {
    background: #fac227 !important;
  }

  &:hover {
    background-color: #fac227 !important;
  }
}

.genIconPressed {
  background-color: #fac227 !important;
}

.genderButtonGroup {
  border-radius: 10px;
}

.datePickermakeEventB {
  width:auto;


  input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    height: 50px;
    text-align: left;

  }
}

.datePickermakeEventB1 {
  width: 82%;


  input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    height: 50px;
    text-align: left;
  }
}

.modalMakeEvt {

  .customInput {
    height: 50px;
    border: none;
    color: #fff !important;
    background: #694b99 !important;
    border-radius: 15px;
  }


  .customInput::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #fff;
  }

  .customInput::-moz-placeholder { /* Firefox 19+ */
    color: #fff;
  }

  .customInput:-ms-input-placeholder { /* IE 10+ */
    color: #fff;
  }

  .customInput:-moz-placeholder { /* Firefox 18- */
    color: #fff;
  }

  .greenBtnEvt {
    background-color: #fac227 !important;
    color: #492ec4;
    border-radius: 50px;
    font-family: "CircularAirBold";
    font-size: 14px !important;
    cursor: pointer;
    transition: 0.3s;
    border: none !important;
    text-transform: capitalize !important;
    border-radius: 50px !important;
    padding: 8px 35px !important;

    &:hover {
      color: #492ec4;
      background-color: #d89e00 !important;
    }
  }

  .dropdown-toggle {
    height: 51px !important;
  }


}


.time_picker_modal_header, .time_picker_modal_footer, .timezone_picker_modal_header {
  background-color: #158868 !important;
}

.picker_pointer .pointer_drag, .picker_pointer, .picker_center {
  background-color: #158868 !important;
}

.react_times_button {
  color: #2d5683 !important;
}

.preview_container svg {
  fill: #2d5683 !important;
}

.borderTop {
  border-top: 1px solid #dedcdc;
}

.firstInput #combo-box-demo4 {
  border: none;
  border-radius: 15px;
  height: 20px;
  padding-left: 10px;
}

.firstInput {
  .MuiAutocomplete-popupIndicator {
    position: relative;
    top: -6px;
    border-left: 1px solid #fff;
    border-right: none;
    border-radius: 0;
    background-color: #694b99 !important;
  }

  .MuiAutocomplete-popupIndicatorOpen {
    border-right: 1px solid #fff;
    border-left: none;
  }

  .MuiAutocomplete-popupIndicator svg {
    font-size: 35px !important;
  }

  .MuiAutocomplete-clearIndicator {
    margin-top: -10px !important;
    margin-right: 5px;
  }
}

.categoryInputB {
  border-radius: 10px;
  background-color: #694b99 !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
}



@media only screen and (max-width: 375px) {
  .categoryInputB {

    width: 92%;
    margin-left: 11px !important;
  }
  .categ-label-dial{
    margin-left: 15px;
  }
}
@media only screen and (max-width: 414px) {
  .categoryInputB {
    width: 93%;
   
    margin-left: 12px !important;
  }
  .categ-label{
    margin-left: 15px;
  }
}

.text-bus {

  color: #2d5683 !important;
  font-size: 16px !important;
  font-weight: 400 !important;

}


.border{
  border: solid black !important;
  width: 350px !important;
}

.makeEventsbusiness .zingMapComponent .autocomplete-dropdown-container {
  position: absolute;
  z-index: 9999;
  display: block;
  border: none;
}

.makeEventsbusiness .location-search-input {
  color: #ffffff !important;
}

.makeEventsbusiness .suggestion-item {
  color: #000000 !important;
}

.makeEventsbusiness .suggestion-item--active {
  color: #ffffff !important;
  background-color: #694b99 !important;
}

.makeEventsbusiness .zingMapComponent .input-group-append {
  position: absolute;
  top: 10px;
  right: 13px;
  border-left: solid 1px #ffffff;
}


@media screen and (max-device-width: 767px) {
  .firstInput {
    .MuiAutocomplete-popupIndicator {
      top: 10px;

    }

  }

}
