.zingMapMarker{
    font-weight: bold;
    color: red;
    display: inline-block;
}
.zingMapMarkerSelected{
    font-weight: bold;
    color: green;
    display: inline-block;
}
.tooltipCompName{
    color: #2d5683;
    cursor: pointer;
    font-family: "CircularAirBook";
    margin:5px 0px;
}
.__react_component_tooltip{
    background-color: #ffffff!important;
    border:solid 1px #d9d9d9!important;
}
.buImage{
    text-align: center;
}
.buText{
    text-align: center;
}
.selectLocationBtn{

    background-color: #3ab54a !important;
    font-size: 12px!important;
    padding: 5px!important;
    margin: 5px 0px!important;
}
.closeLocation{
    color: #000000;
    position: absolute;
    top: 5px;
    right: 5px;
}