@import "../../../Assets/css/variable";
.container {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.border {
  border-width: 1px;
  border-style: solid;
  border-color: red;
}

.imageBoxMyProfile {
  align-self: center !important;
  width: 150px !important;
  height: auto !important;
  background-color: white;
}

.imageBox3 {
  border-style: solid;
  border-color: black;
  border-width: 2px;
  background-color: white;
  min-height: 100px !important;
}

.form-control-file, .form-control-range {
  width: auto !important;
}

.avatar-style {
  align-self: center !important;
  min-width: 150px;
  max-width: 150px;
  width: 150px !important;
  height: 120px !important;
  cursor: pointer;
  margin: 0 !important;
}

.imgPreview {
  border-left: 1px solid rgb(25, 117, 110);
  border-right: 1px solid rgb(25, 117, 110);
  border-top: 2px solid rgb(1, 167, 153);
  border-bottom: 2px solid rgb(1, 167, 153);
  align-self: center !important;
  min-width: 150px;
  max-width: 150px;
  width: 150px !important;
  height: 120px !important;
  margin: 20px 0 5px 17px;
  cursor: pointer;
}

.File-button-stl {
  min-width: 150px;
  max-width: 150px;
  width: 150px !important;
  height: 120px !important;
  color: rgb(0, 117, 108);
  background-color: transparent !important;
  border: 0 !important;
}

.btn-stl {
  margin-left: 15px;
}

.logo_image1 > .my_logo1 {
  height: 65px;
  width: 65px;
  position: absolute;
  margin: 0 !important;
  top: 0;
  z-index: 5;
  margin-left: -2rem !important;
}

.header-M {
  padding: 0 !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 65px !important;

  .head-M2 {
    background-color: #0d878c;
    width: 50%;
  }

  .head-M1 {
    background-color: #19b3bb;
    width: 50%;
  }
}

.Boxes-stl {
  margin-right: 15px !important;
  margin-left: 15px !important;

  .img {
    width: 200px !important;
  }
}

.trans-ition {
  transition: 2s;
}

.back-Button {
  margin-top: -80px;
  align-self: flex-end;
  margin-right: 1rem !important;
  height: 40px;
  width: 40px;
  position: absolute;
  z-index: 5;
  margin-left: 1.5rem !important;
  color: rgb(9, 94, 87) !important;
  cursor: pointer;
  text-align: center;
  font-weight: 700;
  font-size: 15px;
  // font-family: Verdana, Geneva, sans-serif;
}

// .friends-section{
//   border: solid black;
// min-height: 100px !important;
// height: auto !important;

// }
.margin-r-l {
  margin: 10px 10px 10px 10px !important;

}

.under-container {
  margin-top: 40px !important;
}

.save-close-btn {
  background-color: #fac227 !important;
  color: #492ec4 !important;
  font-family: "CircularAirBold";
  font-size: 14px!important;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50px!important;
  border:none!important;
  padding: 7px 30px 7px 30px!important;

  &:hover{
    background-color: #e0a500 !important;
  }
}


.display-row-parent {
  // border: solid 2px black;
  padding: 20px;
  display: flex !important;
  justify-content: flex-end !important;

}

.add-name-cat {
  display: flex !important;
  // flex-direction: column   !important;
  width: 100%;
}
.seeall-btn {
  justify-content: center;
  align-self: center;
}

.socialInput{
  background: #2d5683 !important;
  border-radius: 15px!important;
  color:#fff!important;
}

.socialInput::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color:#fff!important;
}
.socialInput::-moz-placeholder { /* Firefox 19+ */
  color:#fff!important;
}
.socialInput:-ms-input-placeholder { /* IE 10+ */
  color:#fff!important;
}
.socialInput:-moz-placeholder { /* Firefox 18- */
  color:#fff!important;
}


.chooseCategBtn{
  background-color: #fac227 !important;
  color: #492ec4 !important;
  border-radius: 50px!important;
  font-size: 14px!important;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  border:none!important;

  &:hover{
    background-color:#e0a500!important;
  }
}


.saveUrlBtn {
    background-color: #20b55c !important;
  color: white;
  border-radius: 50px!important;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  border:none!important;

  &:hover{
    background-color:#19773f!important;
  }
}
.socialNetworksLinks{
  margin-right: 5px;
    font-family: "CircularAirBold";
    color: #093d77;
    display: flex;
    margin-top: 5px;
}

.profEdit{
  background: transparent!important;
  border:none!important;
  color:#2d5683 !important;
  transition: 0.3s;
  float:right;

  &:hover{
    color:#3ab54a !important;
  }
}
.profEdit-selected{
  background: transparent!important;
  border:none!important;
  color:#694b99 !important;
  transition: 0.3s;
  float:right;
  font-size: 25px !important;
  // background-color:#3ab54a !important ;
  &:hover{
    color:#fac227 !important;
  }
}
.input-group{
  .language-profile{
    // background-color: red !important;
    // max-width: 20% !important;
    // border: solid red !important;
    background: #694b99 !important;
    color: white !important;
    padding-right: 20px !important;
    margin-right: 20px !important;
    border-radius: 10px !important;
  
  }
}


.doneBtn {
  background-color: #20b55c !important;
color: white;
border-radius: 50px!important;
font-size: 14px;
border: none;
cursor: pointer;
transition: 0.3s;
border:none!important;
display: flex;
justify-content: center;
width:100;
max-width:100px;
margin:0 auto;
margin-top:30px;

  &:hover{
    background-color:#19773f!important;
  }
}

.ReactCrop{
  margin-top:20px;
  margin-bottom:20px;
}

.ReactCrop img{
  max-width:400px!important;
  object-fit: cover;

}

.fileChoose{
  font-size:14px!important;
}

.profileInpt{
  font-size:14px!important;
  background-color: #2d5683 !important;
  color:#fff!important;
  height: 40px!important;
  width: 50%!important;


}

.profileInpt::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color:#fff!important;
}
.profileInpt::-moz-placeholder { /* Firefox 19+ */
  color:#fff!important;
}
.profileInpt:-ms-input-placeholder { /* IE 10+ */
  color:#fff!important;
}
.profileInpt:-moz-placeholder { /* Firefox 18- */
  color:#fff!important;
}

.myProfileImage {
  max-width: 80px !important;
  height:80px !important;
  margin: 0 auto;
  width: 100%!important;
  border-radius: 50%;

  object-fit: cover;
}

.buttonUpload{
  font-size:13px!important;
  width:100%;
  margin:0 auto;
  border-radius: 50px!important;
  margin-top:10px;
  color:#fac227 !important;
  background: #fff!important;
  border-color:#fac227 !important;
  font-family: $fontBold;
  transition: 0.5s;
  &:hover{
      background: #fac227 !important;
      color:#fff!important;
  }
}
.buttonUpload-Profile{
  font-size:13px!important;
  width:100%;
  margin:0 auto;
  border-radius: 50px!important;
  margin-top:10px;
  color:#fac227 !important;
  background: #fff!important;
  border-color:#fac227 !important;
  font-family: $fontBold;
  transition: 0.5s;
  &:hover{
      background: #fac227 !important;
      color:#fff!important;
  }
}


.myProfileContainer {
  margin: 0 !important;
  padding: 0 !important;

  .blBackground{
    background: transparent !important; 
    border: none; 
    padding: 0 !important;
    box-shadow: none;
  }

}

.editButton {
  display: flex;
  justify-content: flex-end;
}

.socialNetworkImg {
  width: 35px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.socialNetworkText {
  color: #2d5683 !important;
}

.profileData {
  overflow: hidden;
  .profileDataLabel {
    margin-right: 5px;
    font-family: $fontBold;
    color: #093d77;
    display: flex;

    .svg-inline--fa{
      margin-top: 0.2em!important;
    }


  }
}

.iconTooltipProfile {
  /* // border: solid black !important; */
  margin-top: 10px !important;
  margin-left: 4px;
}
.topParent label{
  margin-bottom:0!important;
  width: 70%;
  word-wrap: break-word;
}

.myProfileEdit {
  select {
    background-color: #2d5683 !important;
    background-image: linear-gradient(45deg, transparent 50%, white 60%),
      linear-gradient(135deg, white 42%, transparent 50%) !important;
    color: #fff !important;
    height: 50px;
    font-size: 15px !important;
    padding-left: 10px !important;
    border-radius: 10px;
  }

  input {
    margin-bottom: 5px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: #fff !important;
    background: #2d5683 !important;
    height: 50px;
    font-size: 15px !important;
    padding-left: 10px !important;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    height: 50px;

    border-left: none;
    border-top: none;
    border-bottom: none;
  }
  ::placeholder {
    color: seashell !important;
  }

  label {
    color: #2d5683 !important;
  }

  .customInput::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #fff;
  }
  .customInput::-moz-placeholder {
    /* Firefox 19+ */
    color: #fff;
  }
  .customInput:-ms-input-placeholder {
    /* IE 10+ */
    color: #fff;
  }
  .customInput:-moz-placeholder {
    /* Firefox 18- */
    color: #fff;
  }

  .grbutton {
    background-color: #20b55c !important;
    color: white;
    border-radius: 50px;
    font-family: "CircularAirBold";
    font-size: 14px;
    border:none;
    cursor: pointer;
    transition: 0.3s;

    &:hover{
      background-color: #146535!important;
    }
  }


}



@media screen and (max-device-width : 767px){

  .buttonUpload-Profile{
    font-size:10px!important;
    width: 45px !important;
    padding: 0 5px 0 5px !important;
    // margin-right: 15px !important;
    &:hover{
        background: #20b55c !important;
        color:#fff!important;
    }
  }

  .modal-body{
    padding-left:5px!important;
    padding-right:5px!important;
  }

  .profileSpace{
    font-size:14px;
    margin-top:30px;
  }

  .myProfileContainer {
    .myProfileImage{
      height: 70px !important;
    }
    
  }

  .showAllProf{
    font-size:13px!important;

  }

  .myProfFriends{


    .friendRow{
      overflow-y: hidden;
      overflow-x: auto;
      flex-wrap: nowrap;

      .friendContainer{
        box-shadow: none;
        min-width: 115px!important;
      }

      .viewProf {
        font-size: 11px;
      }
  
    }

  }
}



@media screen and (max-device-width : 375px){

  .myProfileContainer {
    .myProfileImage{
      height: 62px !important;
    }
    
  }

  .myProfFriends{

    .friendRow{
      .friendContainer{
        box-shadow: none;
        min-width: 103px!important;
      }
    }

  }
}


.makeEvents-profile .location-search-input{
  max-width: 100%;
  border: none;
  background-color: #694b99 !important;
  border-radius: 10px;
  color:#ffffff!important;
  width: 100%;
  padding-right: 44px!important;
  padding-left: 12px!important;
}
.makeEvents-profile .input-group-append{
  position: absolute;
    top: 10px;
    right: 13px;
    border-left: solid 1px #ffffff;
}
.descriptionBox{
  max-height: 100px;
  overflow-y: auto;
  border: 1px solid #eee;
    border-radius: 10px;
    padding:0px 10px;
}
.descriptionBox::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #76f5bc;
}

.descriptionBox::-webkit-scrollbar {
  border-radius: 5px;
  width: 5px;
  background-color: #fff;
}

.descriptionBox::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #093d77;
}