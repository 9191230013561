.zingMapComponent {
  width: 100%;
  // height: 100%;
}

.hpSearchLocation {
  width: 100%;
  position: relative;
}

.hpSearchLocation .hpBtnLocationOpen {
  right: 0px;
  top: 13px;
}

.hpSearchLocation input {
  border: inherit;
}

.zingMapParent {
  height: 100%;
  width: 100%;

}

.zingMapContainer {
  width: 100%;
  height: 100%;
}

.locationDialog {
  zoom: 1;
}

.locationDialog .MuiDialogContent-root {
  padding: 0 !important;
}

.hpSearchLocation .autocomplete-dropdown-container {
  position: absolute;
  z-index: 999;
}

.legendContainer {
  max-width: 325px;
  width: 325px;
  text-align: center;
  position: absolute;
  top: 70px;
  right: 35px;
  border: solid 1px #f7f7f7;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  list-style-type: none;
  max-height: 135px;
}

.zingMapMarkerLegend {
  font-weight: bold;
  color: red;
  display: inline-block;
}

.zingMapMarkerLegend .svg-inline--fa.fa-w-12 {
  width: 16px;
}

.closeLocation {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  margin: 5px;
}

.selectPlaceMap {
  text-align: center;
  margin-top: 8px;
  height: auto !important;
  justify-content: center !important;
  border-radius: 17px !important;
  max-height: 66px;

  input {
    width: 90%;
    color: gray;
    padding-left: 10px;
  }

  input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: gray !important;
  }

  input::-moz-placeholder { /* Firefox 19+ */
    color: gray !important;
  }

  input:-ms-input-placeholder { /* IE 10+ */
    color: gray !important;
  }

  input:-moz-placeholder { /* Firefox 18- */
    color: gray !important;
  }

}
