.notFoundMain{
    height: 100%;
    text-align: center;
    width: 50%;
    margin: auto;
    padding-top: 15%;
    font-size: 25px;
}
.logoImg{
    width:40px;
    height:40px;
    margin: auto;
    margin-bottom: 20px;
}