@import "../../../Assets/css/variable";


$promoBarColor :#27a4b5!important;


.promoBar btn{
    margin-top: 13px;
}

.promoBar1 .backgroundLeft{
  
    width: 100%;
    max-width: 1200px;
    background-position: center;
    height: 310px;
    border-radius: 50px;
}


.promoBar2 .backgroundLeft{
    background: #5546d4;
    width: 100%;
    max-width: 700px;
    height: 271px;
    padding: 24px 0 10px 64px;
    background-position: center;
    box-shadow: 0px 19px 56.44px 11.56px rgba(29, 33, 33, 0.51);
    margin: -11% auto;
    border-radius: 10px;
}

.icons{
    display: flex;
    position: absolute;
    bottom: 0;
    right: 10%;

} 

.promoButtons img{
    max-width:150px;
}

.appBtn{
    max-width: 150px;
}


.promoButtons{
    display: flex;
    flex-direction: column;
}

.promoButtons2{
    position: absolute;
    width: 40%;
    right: -71px;
    top: 77px;
    display: flex;
    flex-direction: column;
}




.appBtn{
  /*   width:50%; */

}

.promotexts{
    padding: 25px 0px;
}

.promoTextB, .promoTextL{
    font-size: 41px;
    line-height: 40px;
    letter-spacing: -2px;
    color:#fff;
}

.promoTextB{
    font-family: $fontBold;
}



.promoTextL{
    font-family: $fontLight;
}



.appBtn{
    cursor: pointer;
    transition: 0.3s;

    &:hover{
        transform: scale(1.05);
    }
}

.promoBar1{

    z-index: 5;
    width: 70%;
    margin: -17% auto;
    margin-bottom: 50px;
    position: relative;
}

.promoBarBack{
    background: url('../../../Assets/img/background_sec2.png') no-repeat;
    background-size:contain;
    width:100%;
    height: 104vh;
}

.promoBar2{
    z-index: 16;
    position: relative;
    width: 54%;
    float: right;
    top: -180px;
}

.contentPromo{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
}


  

.promoBtn{
    background: #fac227 !important;
    font-size:20px!important;
    padding: 0.5em 1em!important;
    border-radius: 5px;
    margin-top:5px;
    border:none!important;
    transition: 0.3s;
    color:#492ec4!important;
    font-family: $fontNormal;
    -webkit-box-shadow: 0px 9px 45.9px 8.1px rgba(29, 33, 33, 0.35);
    -moz-box-shadow: 0px 9px 45.9px 8.1px rgba(29, 33, 33, 0.35);
    box-shadow: 0px 9px 45.9px 8.1px rgba(29, 33, 33, 0.35);

    &:hover{
        background: #f9b909  !important;
    }
}
.event-signUp-btn{
    width: 100% !important;
    background-color: red;
}


.icons img{
    width: 110px;
    position: relative;
    right: 0;
    cursor: pointer;

}

.google{
    width: 80px;

}


@media screen 
and (min-device-width : 1921px) 
and (max-device-width : 3000px) 
{ 
    .promoBar2 {
        width: 70%;
    }

    .promoBar1 {
        margin-top: -32%
    }

}


@media screen 
and (max-device-width : 1680px) 
{ 

    .promoBar2 {
        width: 55%;
    }

}
@media screen 
and (max-device-width : 1280px) 
{ 

    .contentPromo {
        margin-top: -106px;
    }

}
@media screen 
and (max-device-width : 1440px) 
{    
     .contentPromo {
    margin-top: -126px;
}

}
@media screen 
and (max-device-width : 1500px) 
{ 
    .promoBar1{
        margin: -16% auto;
        margin-bottom: 50px;
        width: 89%;
        
    }

    .contentPromo {
        margin-top: 0;
    }

    .promoBar1 .backgroundLeft, .promoBar2 .backgroundLeft{
        height: 240px;
        max-width: 751px;
    }

    .backgroundLeft {
        width: 1140px;
    }

    .promoTextB, .promoTextL {
        font-size: 37px;
        line-height: 37px;
    }

  

    .promoBtn{
        font-size:20px!important;
        padding: 7px 47px !important;
        bottom: 40px;
    }
  

    .promoButtons{
       /*  width:50%; */
    }

    .appBtn {
        width: 90%;
    }

    .promoBar2 {
        width: 71%;
        top: -356px;
    }

    .promoButtons2 {
        width: 18%;
        right: 49px;
        top: 82px;
    }

    .promoButtons{
        position: relative;
        right: -69px;
    
    }

}


@media screen 
and (max-device-width : 1280px) 
{ 

    .appBtn {
        width: 60%;
    }


    .promoBar1 {
        margin: -30% auto;
        margin-bottom:50px;
        width: 83%;
    }

    .backgroundLeft {
        width: 1059px;
    }


    .promoBar2 {
        width: 83%;
        top: -307px;
    }

    .promoButtons{
        right: 0;
        position: relative; 
    }

    .promoButtons2 {
            width: 27%;
    }

    .promoBarBack {
        margin-top: 260px;
    }


}


@media screen 
and (max-device-width : 1024px) 
{ 

    .promoBarBack {
        margin-top: 100px;
    }


    .promoTextB, .promoTextL {
        font-size: 53px;
        line-height: 55px;
    }

    .promoBtn{
        font-size: 23px !important;
        bottom: 30px;
        left: 58px;
    }

   
    .promoBar1 .container-fluid{
        overflow: inherit!important;
    }

       
    .promoBar2 .container-fluid{
        overflow: inherit!important;
    }

    .promoBar1 .backgroundLeft{
        background-size: cover;
        width: 600px;
        height: 210px;
    }

    .promoBar2 .backgroundLeft{
        background-size: cover;
        width: 600px;
        height: 210px;
        margin-left: 0;
    }

    .promoBar2 .backgroundLeft .promoBtn{
        left: 57px;
    }

    .promoBar1{
        width: 95%;
    }

    .promoBar2{
        width: 80%;
        margin: 0 auto;
        left: -30px;

    }

    .promoButtons{
        position: absolute;
        right:100px;
        width: 136px;
        top: -50px;
    }

    .promoButtons2{
        right: 21px;
        top: 42px;
    }

    .promoBtn {
        font-size: 18px !important;
          padding: 10px 19px !important;
        border:none!important;
        font-family: $fontBold;
    }

    .promoTextB, .promoTextL {
        font-size: 37px;
        line-height: 33px;
    }

    .promoBar1 .promoTextB,     .promoBar1 .promoTextL {

        color: #856bd8;
    }

    .appBtn {
        width: 134px;
    }

 

    .promoBar1 {
        margin-top: -300px;
    }

}


@media screen 
and (max-device-width : 1024px) and (orientation : landscape){
    .promoBar1 {
        margin-top: -156px;
    }

    .promoBar2 .backgroundLeft {
        margin-left: 78px;
    }

    .promoBar2 .backgroundLeft .promoBtn {
        left: 55px;
    }

    .promoBar2 {
        left: -83px;
        top: -200px;
        width: 68%;
    }

}




@media screen 
and (max-device-width : 768px) {

    .promoTextB, .promoTextL {
        font-size: 32px;

    }

    .promoBarBack {
        height: 68vh;
    }

}




@media screen 
and (max-device-width : 767px) {

    .contentPromo {
        margin-top: -90px;
    }
 .promoBarBack {
    margin-top: 6px;
}


    .promoBar1 {
        margin-top: 0px;
    }

    .promoBar2 {
        margin-top: 116px;
    }

    .promoBar1 .backgroundLeft {
        width: 100%;
        height: 142px;
    }

    .promoBar2 .backgroundLeft {
        width: 357px;
        height: 176px;
    }

    .promotexts {
        padding: 16px;
    }

    .promoBar1 .promotexts{
        padding-left: 0;
    margin-left: -23px;
    }


    
    .promoTextB, .promoTextL {
        font-size: 20px;
        line-height: 21px;
    }

    .promoBtn {
        font-size: 14px !important;
        margin-left: -28px;
    }

    .appBtn {
        width: 90px;
        margin-bottom: 5px!important;
    }

    .promoBar2 .promotexts{
        padding-left:0;
        margin-left:-25px;
    }

    .promoBar1 {
        width: 92%;
        z-index: 0;
    }

    .promoBar2 {
        width: 92%;
    }


    .promoButtons {
        position: absolute;
        right: 0;
        width: 90px;
        top: -38px;
    }

 
    .contentPromo{
        width: 100%;
        margin-left: -8px;
    }

    .promoBar2 .backgroundLeft{
        margin-left:0;
    }

 

    .promoButtons2 {
        right: 102px;
        top: 42px;
        display: flex;
        flex-direction: column;
        width:0;
    }

}

@media screen 
and (max-device-width : 812px) and (orientation : landscape){
  
}


@media screen 
and (max-device-width : 375px) {
    .promoTextB, .promoTextL {
        font-size: 19px;
    }

    .promoBtn {
        padding: 9px 9px !important;
    }
 

}