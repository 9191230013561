.promotionType {
  justify-content: space-around;
  display: flex;
  text-align: center;
}

.colorBlue {
  color: #2d5683;
  font-weight: bold;
}

.borderRound {
  display: flex;
  border-bottom: #0a91c9 solid 2px;
  justify-content: space-between;
  margin: 10px;
  font-size: 18px;
}

.descriptionStyle {
  color: #2d5683;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-top: 50px;
  width: 100%;
}
.promotionVideo{
  margin: 50px auto auto auto;
  justify-content: center;
  width: 60%;

}
.greenButton {
  background-color: #fac227 !important;
}

.blueButton {
  background-color: #2d5683 !important;
}

.BtnPromotions {
  margin-top: 10px;
  color: white;
  border-radius: 50px;
  font-size: 18px !important;
  cursor: pointer;
  transition: 0.3s;
  border: none !important;
}

.videoUrlHeight {
  height: 50px;
}

.descriptionHeight {
  height: 120px !important;
}

.videoDescriptionInput {
  border: none;
  color: #fff !important;
  background: #694b99 !important ;
  border-radius: 15px;
}


.datepickerPromotions::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: gray !important;
}

.datepickerPromotions::-moz-placeholder { /* Firefox 19+ */
  color: gray !important;
}

.datepickerPromotions:-ms-input-placeholder { /* IE 10+ */
  color: gray !important;
}

.datepickerPromotions:-moz-placeholder { /* Firefox 18- */
  color: gray !important;
}



.videoDescriptionInput::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff !important;
}

.videoDescriptionInput::-moz-placeholder { /* Firefox 19+ */
  color: #fff !important;
}

.videoDescriptionInput:-ms-input-placeholder { /* IE 10+ */
  color: #fff !important;
}

.videoDescriptionInput:-moz-placeholder { /* Firefox 18- */
  color: #fff !important;
}

.locations_promotions {
  width: 95%;
  height: 50px;
  border: none;
  background-color: #694b99 !important;
  border-radius: 8px;
  margin-top: 10px;
  padding-right: 10px;

}

.autocomplete-dropdown-promotions div {
  padding: 7px 5px 7px 15px;
  font-size: 14px;
}

.suggestion-item {
  color: #000000 !important;
}

.suggestion-item--active {
  color: #ffffff !important;
  background-color: #2d5683 !important;
}

.locations_promotions_container {
  width: 100% !important;
  justify-content: space-around !important;
  border-radius: 17px !important;

  input {
    color: #fff !important;
    padding-left: 20px;
  }

  input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #fff !important;
  }

  input::-moz-placeholder { /* Firefox 19+ */
    color: #fff !important;
  }

  input:-ms-input-placeholder { /* IE 10+ */
    color: #fff !important;
  }

  input:-moz-placeholder { /* Firefox 18- */
    color: #fff !important;
  }

}

.calendarPromotions {
  margin-top: 20px;
  display: flex;
  border: none;
  color: #fff !important;
  border-radius: 15px;
}

.disabledCalendar {
  pointer-events: none !important;
}

.addCardBtn {
  background-color: #20b55c !important;
  color: white;
  border-radius: 50px !important;
  font-size: 25px;
  padding-left: 30px!important;
  padding-right: 30px!important;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  border: none !important;

  &:hover {
    background-color: #19773f !important;
  }
}

.promoteButton {
  background-color: #fac227 !important;
  color: #492ec4 !important;
  border-radius: 50px;
  font-family: "CircularAirBold";
  font-size: 14px !important;
  cursor: pointer;
  transition: 0.3s;
  border: none !important;
  text-transform: capitalize !important;
  border-radius: 50px !important;
  padding: 8px 35px !important;
  margin: 20px 10px 20px 10px;

  &:hover {
    background-color: #daa000 !important;
  }
}
.RonSign{
  // border: solid black;


  justify-content: center !important;
}

.swalElement{
  display: flex;
  text-align: center;
  justify-content: center;
}

