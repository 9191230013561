.B_border {
  border-width: 1px;
  border-style: solid;
  border-color: black;
}


.Bookepeer{
min-height: 800px;

  input{
    margin-bottom: 10px;
    border-radius: 10px;
    color: rgb(0, 0, 0) !important;
    background: #fff !important;
    height: 50px;
  }


  input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #492ec4 !important;
  }
  input::-moz-placeholder { /* Firefox 19+ */
    color: #492ec4 !important;
  }
  input:-ms-input-placeholder { /* IE 10+ */
    color: #492ec4 !important;
  }
  input:-moz-placeholder { /* Firefox 18- */
    color: #492ec4 !important;
  }

.BookepeerContent{
  min-height: 150px;
  background: #6a4b9a;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    padding: 50px;
    border-radius: 50px;
    box-shadow: 10px 10px 22px -3px rgba(0, 0, 0, 0.43);
    margin-top: 20px;
    z-index: 1;
    position: relative;
}


.btnGreen{
  background-color: #fac227 !important;
  color: #492ec4;
  border-radius: 50px;
  font-family: "CircularAirBold";
  font-size: 18px !important;
  cursor: pointer;
  transition: 0.3s;
  border: none !important;
  text-transform: capitalize !important;
  border-radius: 50px !important;
  padding: 8px 35px !important;

  &:hover{
    background-color: #dfa300 !important;
    color: #492ec4;
  }
}

.YearParent{
    background: #ffffff !important;
    padding:15px;
    border-radius: 15px;
    color: #492ec4;
}

.PriceParent{
  display: flex;
  align-items: center;
  align-self: center;
  background: #fac227 !important;
  padding:15px;
  border-radius: 15px;
  color:#492ec4;
  text-align: center;
  justify-content: center;
}

.detailsHidd{
  transition: 0.3s;
}

.hiddType{
  background: #2d5683 !important;
  padding:10px;
  border-radius: 15px;
  color:#fff;
}

.hiddPrice{
  display: flex;
  align-items: center;
  align-self: center;
  background: #15826e !important;
  padding:10px;
  border-radius: 15px;
  color:#fff;
  text-align: center;
  justify-content: center;
}

.hiddenValues{
  padding:10px;
}

}
.contents_book{
  margin-top: 25px;
}

.monthsSelect {
  background-color:#ffffff !important;
  border-color: transparent;
  border-radius: 15px !important;
  height: 50px !important;
  padding-left: 20px !important;
  font-size: 15px!important;
  width: 100% !important;
}