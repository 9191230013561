@mixin box-shadow($params) {
  -webkit-box-shadow: $params;
  -moz-box-shadow: $params;
  box-shadow: $params;
}

$shadows: 0px 0px 5px rgba(0, 0, 0, 0.25), 0px 0px 0px #000 inset;
//@include box-shadow($shadows);

.container {
  max-width: 100% !important;
  background: transparent;
  top: 0;
  
}
.navbar-div {
  .nav-icons {
    margin-left: 5px;
    margin-right: 5px;
    width: 27px !important;
    cursor: pointer !important;
  }
}
.button {
  float: right;
}

.logo {
  width: 60px;
  cursor: pointer;
}

.logoBussines{
  width:100px;
}

.profile-img-navbar{
  cursor: pointer;
  transition: 0.5s;

  &:hover{
    transform: scale(0.70);
  }
}

.sponsor {
  width: 160px;
  margin-left: 50%;
  height: 55px;
}

.navbar-toggler::after {
  display: none !important;
}

.navbar-custom .navbar-toggler {
  border: none;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: transparent;
    transform: scale(1.06);
  }

  &:focus {
    background: transparent;
    border: none !important;
    box-shadow: none;
    outline: none;
  }

  &:active {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
}
.dropdownmenuCustom {
  top: 23px !important;
  left: -20px !important;
  min-width: 250px !important;
  border: none !important;
  -webkit-box-shadow: -4px 7px 5px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -4px 7px 5px 1px rgba(0, 0, 0, 0.1);
  box-shadow: -4px 7px 5px 1px rgba(0, 0, 0, 0.1);
  transition: 0.3s !important;
}

.dropdown {
  outline: none !important;
}

.itemCustom {
  color: #1bb0b0 !important;
  transition: 0.2s;
  padding: 10px;
  font-size: 15px;

  &:hover {
    width: 95%;
    color: #083d77 !important;
    background: #1bb0b0 !important;
    margin: auto;
    border-radius: 3px;
  }
}

.Navbar {
  background: var(--navbar-background-color);
  transition: 0.6s;
  justify-content: space-between !important;
}
.err-text {
  text-align: center !important;
  margin-top: 30px;
}
.err-btn {
  justify-content: center;
}
.appBar {
  position: relative;
}
.saveClose-Parent {
  align-self: flex-end;
  margin-right: 1rem !important;
  height: 40px;
  width: 40px;
  position: absolute;
  z-index: 5;
  margin-left: -1.5rem !important;
  background-color: transparent !important;
  color: rgb(15, 182, 168) !important;
  cursor: pointer;
  text-align: center;
  font-weight: 700;
  font-size: 25px;
  font-family: Verdana, Geneva, sans-serif;
}
.Categ-Modal {
  height: auto !important;
  width: auto !important;
}
// @media (min-width: 576px) {
//   .costume-modal-prof {
//     max-width: 920px !important;
//     margin: 3.75rem auto;
//   }
// }
.costume-modal-prof {
  // max-width: 920px !important;
width: 97% !important;
  max-height: 500px !important;
  margin: 3.75rem auto;
}
.row {
  margin-right: 15px !important;
  margin-left: 15px !important;
}
.categ-save-btn {
  justify-content: center !important;
}

.popover {
  max-width: 100%;
  padding: 0px;
}

.numberCircleBus{
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background: #f95656 !important;
  color: #ffffff;
  text-align: center;
  font: 15px Arial, sans-serif;
  position: absolute;
  top: -10px;
  right: -10px;
}
.notificationsContainerBus{
  position: relative;
}
.onlyNumberBus{
  border-radius: 50%;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  line-height: 24px;
}

.Navbar-business {
    background: #fff;
    transition: 0.6s;
    height: 80px !important;
    position: fixed !important;
    border-radius: 0 0 15px  15px  !important;
    

    .centerSponsor{
      margin:0 auto;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  
  }

  .busUsr .bm-burger-button{
    top: 25px;
    right: 3%;
    width: 32px;
  height: 28px;
  }

  .busUsr .bm-burger-bars {
    height: 7px !important;
  }

  .busUsr .bm-burger-button span:nth-child(2) {
    width: 39px !important;
}


  
  
  .d-flex-costume{
    margin-right: 4% !important;
    // border: solid red !important\;
  }
  .image-nav-business{
    height: 70px !important;
    width: 70px !important;
    border-radius: 50px !important;
    
  }


  @media screen and (max-device-width : 767px){
    .Navbar-business{
      height: 60px !important;
    }


    .d-flex-costume{
      margin-right:0!important;
    }



  }

