body{
    overflow-y: scroll;
}
.cookieMain{
    width:80%;
    text-align: center;
    margin: auto;
}
.logoImg{
    width:40px;
    height:40px;
    margin: auto;
}
.fullCentered{
    width:100%;
    text-align: center;
}
.leftalign{
    text-align: left;
}
.leftalign table tr:first-child{
    background-color:#D9D9D9;
}
.leftalign table td{
    border:solid 1px #000000;
}
.cookiesMargins{
    margin-top: 10px;
    margin-bottom: 10px;
}