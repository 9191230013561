$yesColor: green;
$noColor: red;
$deleteColor: gray;


















.edtButtons-voucher{
  padding: 0 10px 0 10px !important;

}
.joinButton-voucher {
  margin-top: 30px;
  background-color: #4e178c !important;
  font-size: 15px !important;
  // border: none !important;
  border-radius: 10px !important;
  justify-content: center;
  animation: pulse 2s infinite;

  &:hover {
    background: #6a4b9a !important;
  }
}

.parent-btn-grup {

  align-self: center !important;
  justify-content: space-around !important;
  align-content: space-around !important;
  align-items: center !important;
  
}
.parentPen{
  width: 100%;
  border: solid black;
  align-self: flex-end !important;
  justify-content: flex-end !important;
  align-content: flex-end !important;
  align-items: flex-end !important;
  display: block !important;

}
.VoucherPen {
  align-self: flex-end !important;
  justify-content: flex-end !important;
  align-content: flex-end !important;
  align-items: flex-end !important;

  border: solid black;
  width: 100%;
  background: transparent !important;
  color: #4e178c !important;
  font-size: 20px !important;
  transition: 0.2s !important;
  position: relative;
  z-index: 2;

  &:hover {
    transform: scale(1.08);
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}
















.ImgboxVoucer {
  width: 150px !important;
  height: 50px !important;
  background-color: magenta;
}

.yesVoucher {
  background-color: $yesColor;
}

.noVoucher {
  background-color: $noColor;
}

.deleteVoucher {
  background-color: $deleteColor;
}

.voucherContainer {
  z-index: 0 !important;
  border-radius: 15px;
  background-color: white !important;
  -webkit-box-shadow: 6px 6px 28px -6px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: 6px 6px 28px -6px rgba(0, 0, 0, 0.47);
  box-shadow: 6px 6px 28px -6px rgba(0, 0, 0, 0.47);
  margin: 10px 0px;

  .centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.addCardBtn {
  background-color: #20b55c !important;
  color: white;
  border-radius: 50px !important;
  font-size: 25px;
  padding-left: 30px!important;
  padding-right: 30px!important;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  border: none !important;

  &:hover {
    background-color: #19773f !important;
  }
}

.voucherConditions {
  background-color: white !important;
  border-radius: 15px;
  color: #2d5683;
  border: 1px solid #959595;
  //cursor: pointer;
  font-family: "CircularAirBook";
  padding: 5px;
  padding-left: 10px;
  font-size: 16px;
}
