$backgroundIwant: green;

.imageBox {
  width: 100px !important;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  background-color: white;
}

.border {
  border-width: 1px;
  border-style: solid;
  border-color: black;
}

.iWantInBackground {
  background-color: $backgroundIwant;
}

.participant {
  height: 50px !important;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  background-color: $backgroundIwant;
}

.messageBox::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #f5f5f5;
}

.messageBox::-webkit-scrollbar {
  border-radius: 5px;
  width: 5px;
  background-color: #f5f5f5;
}

.messageBox::-webkit-scrollbar-thumb {
  border-radius: 5px;
  // background-color: $blueevents;
}

.messageBox {
  height: 80px;
  overflow: auto;
  overflow-x: hidden;
  padding-left: 5px;
  padding-right: 15px;
}
