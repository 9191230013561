.notificationBody{
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px #f1f1f1;
    max-width: 300px !important;
    cursor: pointer;

  .notificationsImg{
    margin: 0px 5px 0px 5px;
  }
  img{
    max-width: 30px;
    border-radius: 50%;
    object-fit: cover;
    height:30px;

  }

  .notificationMsg{
    font-size:12px;
    padding: 0px 5px 5px 5px;
  }

  .notificationName, .notificationCategory,.notificationEvent{
    color:#2d5683 !important;
    font-weight: bold;
  }

  .notificationCategory{
    text-transform: capitalize;
  }
  &:hover{
    background-color: #f7f7f7;
  }
}


.popoverNotifications .popover {
  border:none;
  -webkit-box-shadow: 4px 10px 22px -3px rgba(0,0,0,0.43);
-moz-box-shadow: 4px 10px 22px -3px rgba(0,0,0,0.43);
box-shadow: 4px 10px 22px -3px rgba(0,0,0,0.43);
}

.popoverNotifications .arrow::before{
  border-bottom-color:transparent!important;
}
.notReadit{
  background-color: #2d568324;
}