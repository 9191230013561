.myTicketsContainer{
    height: 450px !important;
    overflow: auto;
    overflow-x: hidden;
    z-index: 9000 !important;
    padding-right: 30px;

    .row{
        background-color: #ffffff;
        border-radius: 30px;
        margin:10px 0px!important;
    }
    .rowShadow{
        box-shadow: 9px 6px 29px -6px rgba(0, 0, 0, 0.31);
    }
    .profilePictureTickets{
        display: flex;
        align-items: center;
    }
    .priceTitleText{
        font-family: "CircularAirBold";
        font-size: 22px;
        color: #2d5683;
        margin-top: -6px;
        margin-bottom: 13px;
    }
    .priceDetailsText{
        position: absolute;
        font-family: "CircularAirBold";
        font-size: 16px;
        color: #2d5683;
        top: 10px ;
        right: 15px;
        text-align: right!important;
        cursor: pointer;
    }
    .eventProfilePicTickets{
        cursor: pointer;
        height: 60px;
        width: 60px;
        border-radius: 50px;
    }
}
.secH{
    max-width: 100% !important;
    max-height: 100px;
    overflow: auto;
    margin-left: 5px;
    font-weight: 100 !important;
    font-size: 15px !important;
    margin-top: 2px;
}
.noMrgCol.col{
  
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.parentColsEvt{
    padding-top:20px ;
}
.noMrgColName{
    padding-top: 20px !important;
    font-family: "CircularAirBold";
    font-size: 22px;
    color: #fff;
    display: flex;
}
.noMrgCol{
    margin-top: 15px !important;
    font-family: "CircularAirBold";
    font-size: 22px;
    color: #fff;
    display: flex;
}
.modalStle{
    width: 100% !important;
}
.ticketsNrBtn{
    // border: solid black !important;
    cursor: pointer;
}
.positionBarcode{
    position: absolute !important;
    width: 100% !important;
    right: 0;
    top: 0;
}
.ticketsArea{
    
    
    height: auto;
    border: solid #838ae5 !important;
    display: block;
    flex-direction: row !important;
    border-radius: 20px;
    margin: 5px;
    background-color: #ffffff  !important;
    -webkit-box-shadow: 4px 10px 22px -3px rgba(0,0,0,0.43);
    -moz-box-shadow: 4px 10px 22px -3px rgba(0,0,0,0.43);
    box-shadow: 4px 10px 22px -3px rgba(0,0,0,0.43);
}
.parrentBarcode .row{
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.noMarginRow .row{
    margin-left: 0 !important;
    margin-right: 0 !important;
    display: flex !important;

}
.parrentBarcode{
    border-radius: 15px;
    background-image: url("../../../Assets/img/lastImageTicketss.jpg");
    background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
    width: 100% !important;
    min-height: 100px;
//   flex-direction: column !important;
}
.modalBodyBarcode{
max-height:700px;
overflow-y: auto;
overflow-x: hidden;

   

}

.myTicketsContainer::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #76f5bc;
  }
  
  .myTicketsContainer::-webkit-scrollbar {
    border-radius: 5px;
    width: 5px;
    background-color: #fff;
  }
  
  .myTicketsContainer::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #093d77;
  }
@media screen and (max-device-width : 767px){
    .parentColsEvt{
        padding-top:5px ;
    }
    .noMrgColName{
        margin-top: 70px !important;
    }
    .ticketsArea{
        height: auto;
        border: solid #838ae5 !important;
        display: block;
        flex-direction: row !important;
        border-radius: 20px;
        // margin: 0 0 0 0 ;
        width: 100% !important;
        margin: 20px   0 20px 0 !important;
        background-color: #ffffff ;
        -webkit-box-shadow: 4px 10px 22px -3px rgba(0,0,0,0.43);
        -moz-box-shadow: 4px 10px 22px -3px rgba(0,0,0,0.43);
        box-shadow: 4px 10px 22px -3px rgba(0,0,0,0.43);
    }
    .positionBarcode{
        position: absolute !important;
        border: none !important;
        width: 100% !important;
        right: auto;
        left: 60px;
        top: 5px;
    }
    .priceDetailsText{
        font-family: "CircularAirBold";
        font-size: 16px;
        color: #2d5683;
        top: -40px !important;
        // margin-top:  !important;
        // margin-bottom: 13px;
        text-align: right!important;
        cursor: pointer;
    }
    .eventProfilePicTickets{
        margin-top: 10px;
        cursor: pointer;
        height: 50px !important;
        width: 50px !important;
        border-radius: 50px;
    }
    .priceTitleText{
        font-family: "CircularAirBold";
        font-size: 13px !important;
        color: #2d5683;
        margin-top: -6px;
        margin-bottom: 13px;
    }
}